import React, { createContext, useState, useEffect, useCallback } from "react";
import api from "../api/api";
import {
  decryptKeyValue,
  encryptKeyValue,
  normalizePermissions,
} from "../config/utility";
import { AUTH_TOKEN_KEY } from "../config/constants";

// Create the context
const UserContext = createContext();

const retrieveStoredData = () => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  const roles = decryptKeyValue("roles") || [];
  const rawPermission = decryptKeyValue("permission") || {};
  const user = decryptKeyValue("user");

  // Only normalize and store permissions if the role is "user"
  const permission = roles.includes("user") ? normalizePermissions(rawPermission) : {};

  return {
    token,
    roles,
    permission,
    user: user ? { ...user, isAuthenticated: true } : null,
  };
};

// UserContextProvider component
const UserContextProvider = ({ children }) => {
  const [token, setToken] = useState(() => retrieveStoredData().token);
  const [roles, setRoles] = useState(() => retrieveStoredData().roles);
  const [permission, setPermission] = useState(() => retrieveStoredData().permission);
  const [user, setUser] = useState(() => retrieveStoredData().user);
  const [needsRedirect, setNeedsRedirect] = useState(false);

  const contextLogin = useCallback((response) => {
    const { token, user } = response;

    // Store token, roles, and user data
    localStorage.setItem(AUTH_TOKEN_KEY, token);
    encryptKeyValue("roles", user.role);
    encryptKeyValue("user", user);

    // Set state based on the response
    setToken(token);
    setRoles(user.role || []);
    setUser({ ...user, isAuthenticated: true });
    setNeedsRedirect(false);

    // Only store permissions if the user role is "user"
    if (user.role.includes("user")) {
      encryptKeyValue("permission", user.permission);
      setPermission(normalizePermissions(user.permission));
    } else {
      localStorage.removeItem("permission"); // Remove any existing permission for other roles
      setPermission({});
    }
  }, []);

  const clearUserData = () => {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem("roles");
    localStorage.removeItem("permission");
    localStorage.removeItem("user");
    setUser(null);
    setRoles([]);
    setPermission({});
    setToken(null);
  };

  const contextLogout = useCallback(() => {
    api
      .post("/logout")
      .then(() => {
        clearUserData();
        setNeedsRedirect(true);
      })
      .catch((error) => {
        console.error("Logout failed:", error.response?.data);
      });
  }, []);

  // const updateContext = useCallback(() => {
  //   api
  //     .get("/user/info")
  //     .then((response) => {
  //       const { user } = response.data;
  //       encryptKeyValue("roles", user.role);
  //       encryptKeyValue("user", user);

  //       setRoles(user.role || []);
  //       setUser({ ...user, isAuthenticated: true });
  //       setNeedsRedirect(false);

  //       // Only update permissions if the user role is "user"
  //       if (user.role.includes("user")) {
  //         if (user.permission) {
  //           encryptKeyValue("permission", user.permission);
  //           setPermission(normalizePermissions(user.permission));
  //         }
  //       } else {
  //         localStorage.removeItem("permission");
  //         setPermission({});
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("User info retrieval failed:", error.response?.data);
  //     });
  // }, []);


  const updateContext = useCallback((callback = null) => {
    return api
      .get("/user/info")
      .then((response) => {
        const { user } = response.data;
        encryptKeyValue("roles", user.role);
        encryptKeyValue("user", user);

        setRoles(user.role || []);
        setUser({ ...user, isAuthenticated: true });
        setNeedsRedirect(false);

        // Only update permissions if the user role is "user"
        if (user.role.includes("user")) {
          if (user.permission) {
            encryptKeyValue("permission", user.permission);
            setPermission(normalizePermissions(user.permission));
          }
        } else {
          localStorage.removeItem("permission");
          setPermission({});
        }

        // Execute the callback if provided
        if (callback) {
          callback(user);
        }

        return user; // Return the user data for promise chaining
      })
      .catch((error) => {
        console.error("User info retrieval failed:", error.response?.data);
        throw error; // Rethrow the error for additional handling
      });
  }, []);


  useEffect(() => {
    const { token, roles, permission, user } = retrieveStoredData();
    if (token && user) {
      setToken(token);
      setRoles(roles);
      setUser(user);

      // Set permissions only if the role is "user"
      if (roles.includes("user")) {
        setPermission(permission);
      } else {
        setPermission({});
      }
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        token,
        roles,
        permission,
        needsRedirect,
        contextLogin,
        contextLogout,
        updateContext,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
