import React, { useContext } from 'react';
import StatsCard from './../components/dashboard/StatsCard';
import CustomerMap from '../components/dashboard/CustomerMap';
import TotalRevenue from '../components/dashboard/TotalRevenue';
import { UserContext } from '../context/UserContext';
import UserDashboard from './User/Dashboard';
import UnpaidUserDashboard from './User/UnpaidDashboard';
import AdminDashboard from './Admin/Dashboard';
import AdminProfile from './Admin/Profile';
import UserProfile from './User/Profile';
import UserBooking from './User/Booking';
import StaffBooking from './Staff/Bookings';
import AdminBooking from './Admin/Bookings';

const Booking = () => {
  const { user, roles } = useContext(UserContext); // Get the roles from context

  return (
    <div className="p-6 space-y-6">
      {roles.includes('admin') && (
        <AdminBooking user={user}  />
      )}

      {roles.includes('user') && (
        user.paid ? <UserBooking user={user} /> : <UnpaidUserDashboard />
      )}

      {roles.includes('staff') && (
        <StaffBooking user={user} />
      )}

    </div>
  );
};

export default Booking;
