import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrash, faEdit, faStar, faSpinner } from "@fortawesome/free-solid-svg-icons";
import api from "../../api/api";
import { UserContext } from "../../context/UserContext";

const AddressManager = () => {
    const { user, updateContext } = useContext(UserContext);
    const [addresses, setAddresses] = useState(user?.addresses || []);
    const [formData, setFormData] = useState({
        street_address: "",
        apartment_number: "",
        city: "",
        postal_code: "",
        additional_notes: "",
        is_primary: false,
    });
    const [editingAddress, setEditingAddress] = useState(null);
    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState({}); // Track which address is being edited, deleted, or set as primary

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    // Save Address (Create or Update)
    const handleSaveAddress = async () => {
        if (!formData.street_address || !formData.city || !formData.postal_code) {
            toast.error("Street Address, City, and Postal Code are required.");
            return;
        }

        const payload = {
            address: { ...formData },
            _method: "PUT",
        };

        if (editingAddress) {
            payload.address.id = editingAddress.id;
        }

        setLoading(true);
        try {
            await api.post("/user/update", payload);
            const user = await updateContext();
            setAddresses(user.addresses);
            toast.success(editingAddress ? "Address updated successfully!" : "Address added successfully!");
            resetForm();
        } catch (error) {
            toast.error("Failed to save address. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    // Reset Form
    const resetForm = () => {
        setFormData({
            street_address: "",
            apartment_number: "",
            city: "",
            postal_code: "",
            additional_notes: "",
            is_primary: false,
        });
        setEditingAddress(null);
    };

    // Edit Address
    const handleEditAddress = (address) => {
        setFormData({ ...address });
        setEditingAddress(address);
    };

    // Delete Address
    const handleDeleteAddress = async (address) => {
        const payload = {
            address: { ...address, should_delete: true },
            _method: "PUT",
        };

        setActionLoading((prev) => ({ ...prev, [address.id]: true }));
        try {
            await api.post("/user/update", payload);
            const user = await updateContext();
            setAddresses(user.addresses);
            toast.success("Address deleted successfully!");
        } catch (error) {
            toast.error("Failed to delete address. Please try again.");
        } finally {
            setActionLoading((prev) => ({ ...prev, [address.id]: false }));
        }
    };

    // Set Primary Address
    const handleSetPrimary = async (address) => {
        const payload = {
            address: { ...address, is_primary: true },
            _method: "PUT",
        };

        setActionLoading((prev) => ({ ...prev, [address.id]: true }));
        try {
            await api.post("/user/update", payload);
            const user = await updateContext();
            setAddresses(user.addresses);
            toast.success("Primary address set successfully!");
        } catch (error) {
            toast.error("Failed to set primary address. Please try again.");
        } finally {
            setActionLoading((prev) => ({ ...prev, [address.id]: false }));
        }
    };

    return (
        <div className="bg-white p-8 rounded-3xl shadow-lg mt-8">
            <h2 className="text-3xl font-bold text-primary mb-6 text-center">Manage Addresses</h2>

            {/* Address Form */}
            <div className="bg-gray-50 p-6 rounded-lg shadow-inner mb-8">
                <h3 className="text-xl font-semibold text-primary mb-4">
                    {editingAddress ? "Edit Address" : "Add New Address"}
                </h3>
                <div className="grid grid-cols-2 gap-6">
                    <div>
                        <label className="block text-gray-600 font-semibold">Street Address</label>
                        <input
                            type="text"
                            name="street_address"
                            value={formData.street_address}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary"
                            placeholder="Enter street address"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-600 font-semibold">Apartment Number</label>
                        <input
                            type="text"
                            name="apartment_number"
                            value={formData.apartment_number}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary"
                            placeholder="Enter apartment number"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-600 font-semibold">City</label>
                        <input
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary"
                            placeholder="Enter city"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-600 font-semibold">Postal Code</label>
                        <input
                            type="text"
                            name="postal_code"
                            value={formData.postal_code}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary"
                            placeholder="Enter postal code"
                        />
                    </div>
                    <div className="col-span-2">
                        <label className="block text-gray-600 font-semibold">Additional Notes</label>
                        <textarea
                            name="additional_notes"
                            value={formData.additional_notes}
                            onChange={handleInputChange}
                            className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary"
                            placeholder="Enter additional notes"
                        ></textarea>
                    </div>
                    <div className="col-span-2 flex items-center">
                        <input
                            type="checkbox"
                            name="is_primary"
                            checked={formData.is_primary}
                            onChange={handleInputChange}
                            className="mr-2"
                        />
                        <label className="text-gray-600 font-medium">Set as Primary Address</label>
                    </div>
                </div>
                <button
                    onClick={handleSaveAddress}
                    disabled={loading}
                    className="w-full py-3 mt-4 bg-light text-primary  rounded-lg font-bold"
                >
                    {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faSave} />}
                    {editingAddress ? " Update Address" : " Save Address"}
                </button>
            </div>

            {/* Address List */}
            <h3 className="text-2xl font-semibold text-primary mb-6">Saved Addresses</h3>
            <div className="space-y-6">

                {addresses.map((address) => (
                    <div key={address.id} className={`relative p-6 border rounded-lg ${address.is_primary ? "bg-light" : "bg-gray-50"}`}>

                        {actionLoading[address.id] && (
                            <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
                                <FontAwesomeIcon icon={faSpinner} spin size="2x" className="text-primary" />
                            </div>
                        )}
                        <p>{address.street_address}</p>
                        <p>{address.city}, {address.postal_code}</p>

                        <div className="mt-4 flex items-center space-x-4">
                            <button
                                onClick={() => handleEditAddress(address)}
                                disabled={actionLoading[address.id]}
                                className="text-blue-500"
                            >
                                <FontAwesomeIcon icon={faEdit} />
                                Edit
                            </button>
                            <button
                                onClick={() => handleDeleteAddress(address)}
                                disabled={actionLoading[address.id]}
                                className="text-red-500"
                            >
                                <FontAwesomeIcon icon={faTrash} />
                                Delete
                            </button>
                            {!address.is_primary && (
                                <button
                                    onClick={() => handleSetPrimary(address)}
                                    disabled={actionLoading[address.id]}
                                    className="text-primary"
                                >
                                    <FontAwesomeIcon icon={faStar} />
                                    Set as Primary
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AddressManager;
