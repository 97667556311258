import React from "react";
import ApplyForMembership from "./ApplyForMembership";

const UnpaidUserDashboard = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 px-6 min-h-screen">
      {/* Hero Section */}
      <div className="lg:col-span-2 w-full bg-ash p-12 rounded-3xl  hover:shadow-primary transition-transform transform  space-y-8">
        <h2 className="text-5xl font-extrabold text-primary text-center">
           Choose Your Luxury Today!
        </h2>
        <p className="text-lg text-body text-center max-w-3xl mx-auto">
          Select from our exclusive membership options and indulge in unmatched care,
          tailored just for you. Let’s elevate your salon experience.
        </p>
        <ApplyForMembership />
      </div>

      {/* How Our Booking System Works */}
      <section className="py-16  lg:col-span-2">
        <h2 className="text-5xl font-extrabold text-center text-primary mb-16">
          📅 How Our Booking System Works
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 px-8">
          {[
            {
              title: "1️⃣ Select Your Services",
              description: "Browse our range of manicures, pedicures, and nail treatments. Choose your desired services and customize with extras like Nail Art or Gel Tips.",
              image: "https://via.placeholder.com/150/FFB6C1/000000?text=Services",
            },
            {
              title: "2️⃣ Choose Date & Time",
              description: "Pick your preferred date and time using our live availability calendar. We'll show you all the available slots for your convenience.",
              image: "https://via.placeholder.com/150/FFB6C1/000000?text=Date+%26+Time",
            },
            {
              title: "3️⃣ Confirm & Relax",
              description: "Sit back and relax. Visit our salon or wait for our team to arrive at your home (for Dyo members). Indulge in a luxury experience.",
              image: "https://via.placeholder.com/150/FFB6C1/000000?text=Relax",
            },
          ].map((step, index) => (
            <div
              key={index}
              className="bg-white p-10 rounded-3xl shadow-lg hover:shadow-xl transition-transform hover:scale-105 text-center flex flex-col items-center"
            >
              {/* Step Icon */}
              <img
                src={step.image}
                alt={step.title}
                className="w-32 h-32 object-cover rounded-full mb-6"
              />

              {/* Step Title */}
              <h3 className="text-2xl font-bold text-primary mb-4">
                {step.title}
              </h3>

              {/* Step Description */}
              <p className="text-gray-600 leading-relaxed">
                {step.description}
              </p>
            </div>
          ))}
        </div>
      </section>


      {/* <div className="relative w-full bg-gradient-to-br from-primary via-secondary to-accent p-12 rounded-3xl shadow-md text-white text-center hover:shadow-primary transition-transform transform ">
        <div className="absolute inset-0 opacity-10 bg-[url('https://www.transparenttextures.com/patterns/sparkle.png')] bg-cover rounded-3xl"></div>
        <div className="relative z-10">
          <h1 className="text-6xl font-extrabold mb-4 leading-tight">
            ✨ Experience Elegance ✨
          </h1>
          <p className="text-lg font-medium mb-6">
            Transform your routine into a luxurious escape. Unlock exclusive benefits,
            priority bookings, and indulgent care with our membership plans. 💎
          </p>
          <ul className="space-y-4 text-left text-lg font-medium mx-auto max-w-2xl">
            <li className="flex items-center">
              <span className="flex items-center justify-center w-8 h-8 bg-white rounded-full text-primary font-bold mr-3">
                1
              </span>
              Priority access to all premium services.
            </li>
            <li className="flex items-center">
              <span className="flex items-center justify-center w-8 h-8 bg-white rounded-full text-primary font-bold mr-3">
                2
              </span>
              Unlimited in-salon luxuries with exclusive add-ons.
            </li>
            <li className="flex items-center">
              <span className="flex items-center justify-center w-8 h-8 bg-white rounded-full text-primary font-bold mr-3">
                3
              </span>
              Tailored discounts designed just for you.
            </li>
          </ul>
          <div className="mt-8">
            <button
              className="px-12 py-4 bg-white text-primary font-bold rounded-full shadow-lg hover:bg-light hover:scale-110 transition-transform transform"
              onClick={() => alert("Explore Membership Benefits")}
            >
              Discover More 
            </button>
          </div>
        </div>
      </div> */}

      {/* Testimonials Section */}

      {/* <div className=" bg-gradient-to-br from-secondary to-primary text-white p-10 rounded-3xl shadow-lg hover:shadow-primary transition-transform transform ">
        <h3 className="text-4xl font-bold text-center mb-8">
          💎 What Our Members Say
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {[
            {
              name: "Sophia R.",
              quote: "The priority bookings and luxurious treatments are unmatched. Best decision ever!",
              image: "https://via.placeholder.com/100?text=Member1",
            },
            {
              name: "Amelia W.",
              quote: "I feel so pampered every time I visit. The membership perks are totally worth it!",
              image: "https://via.placeholder.com/100?text=Member2",
            },
            {
              name: "Olivia L.",
              quote: "The staff, services, and attention to detail are simply amazing. Highly recommend!",
              image: "https://via.placeholder.com/100?text=Member3",
            },
          ].map((testimonial, index) => (
            <div
              key={index}
              className="bg-white text-primary p-6 rounded-3xl shadow-lg text-center"
            >
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="w-20 h-20 rounded-full mx-auto mb-4 border-4 border-accent"
              />
              <p className="text-lg font-medium mb-4">"{testimonial.quote}"</p>
              <h4 className="text-xl font-bold">{testimonial.name}</h4>
            </div>
          ))}
        </div>
      </div> */}

      {/* Membership Section (Full Width Row) */}

    </div>
  );
};

export default UnpaidUserDashboard;
