import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faCalendarPlus, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { UserContext } from "../../context/UserContext";
import api from "../../api/api";

const StaffDashboard = () => {
  const { user } = useContext(UserContext);
  const [stats, setStats] = useState({
    todayBookings: 0,
    totalBooked: 0,
    totalCompleted: 0,
  });
  const [bookings, setBookings] = useState({
    today: { count: 0, bookings: { data: [], prev_page_url: null, next_page_url: null } },
    booked: { count: 0, bookings: { data: [], prev_page_url: null, next_page_url: null } },
    completed: { count: 0, bookings: { data: [], prev_page_url: null, next_page_url: null } },
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.get("/home");
      const { staff_bookings } = response.data;

      setStats({
        todayBookings: staff_bookings.today.count,
        totalBooked: staff_bookings.booked.count,
        totalCompleted: staff_bookings.completed.count,
      });

      setBookings({
        today: staff_bookings.today,
        booked: staff_bookings.booked,
        completed: staff_bookings.completed,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (type, url) => {
    try {
      const response = await api.get(url);
      const updatedData = response.data;
      console.log(response)
      setBookings(prev => ({
        ...prev,
        [type]: updatedData.staff_bookings[type]
      }));
    } catch (error) {
      console.error("Error paginating data:", error);
    }
  };

  return (
    <div className="px-6 space-y-12">
      {/* Welcome Banner */}
      <div className="rounded-3xl bg-gradient-to-r from-primary to-gradientEnd p-10 text-center text-white">
        <h1 className="text-4xl font-bold">Welcome Back </h1>
        <p className="text-lg mt-4">Track your bookings, check today's schedule, and plan ahead for a smooth day.</p>
      </div>

      {/* Stats Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {[
          { label: "Today's Bookings", count: stats.todayBookings, icon: faCalendarPlus, color: "bg-primary" },
          { label: "Total Booked", count: stats.totalBooked, icon: faCalendarCheck, color: "bg-accent" },
          { label: "Completed Bookings", count: stats.totalCompleted, icon: faCheckCircle, color: "bg-gradient-to-r from-primary to-accent" },
        ].map((item, idx) => (
          <motion.div key={idx} className={`p-6 rounded-3xl shadow-lg flex items-center space-x-6 ${item.color} text-white`}>
            <div className="w-16 h-16 flex items-center justify-center rounded-full bg-white">
              <FontAwesomeIcon icon={item.icon} className="text-3xl text-primary" />
            </div>
            <div>
              <h4 className="text-2xl font-bold">{item.count}</h4>
              <p className="text-lg">{item.label}</p>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Booking Tables */}
      {['today', 'booked', 'completed'].map((type, idx) => (
        <div key={idx} className="bg-white p-6 rounded-3xl shadow-lg">
          <h2 className="text-2xl font-bold text-primary mb-6">
            {type.charAt(0).toUpperCase() + type.slice(1)} Bookings
          </h2>

          <div className="overflow-auto">
            <table className="w-full text-left">
              <thead>
                <tr>
                  <th className="py-4">Name</th>
                  <th className="py-4">Date</th>
                  <th className="py-4">Status</th>
                </tr>
              </thead>
              <tbody>
                {bookings[type]?.bookings?.data?.length > 0 ? (
                  bookings[type].bookings.data.map((booking, idx) => (
                    <tr key={idx} className="border-b hover:bg-light">
                      <td className="py-4">{booking.user?.name || "N/A"}</td>
                      <td className="py-4">{booking.day || "N/A"}</td>
                      <td className="py-4">{booking.status || "N/A"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center py-4 text-gray-500">
                      No bookings available.
                    </td>
                  </tr>
                )}
              </tbody>

            </table>
          </div>

          {/* Pagination */}
          <div className="flex justify-between mt-4">
            {bookings[type]?.bookings.prev_page_url && (
              <button
                onClick={() => handlePageChange(type, bookings[type].bookings.prev_page_url)}
                className="px-4 py-2 bg-primary text-white rounded-full"
              >
                Previous
              </button>
            )}
            {bookings[type].bookings.next_page_url && (
              <button
                onClick={() => handlePageChange(type, bookings[type].bookings.next_page_url)}
                className="px-4 py-2 bg-primary text-white rounded-full"
              >
                Next
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StaffDashboard;
