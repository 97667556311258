import React, { useState } from 'react';
import { toast } from 'react-toastify';

const AdditionalDetails = ({ formData, setFormData, userAddresses = [] }) => {
    const [imagePreviews, setImagePreviews] = useState(formData?.reference_images || []);

    // Handle text input for additional details
    const handleInputAddressChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            new_address: {
                ...formData.new_address,
                [name]: type === "checkbox" ? checked : value
            }
        });

    };

    // Handle location selection (home-based or in-salon)
    const handleLocationChange = (location) => {
        setFormData({ ...formData, location });

        // Reset address-related fields if Home Service is selected
        if (location === 'home') {
            setFormData({ ...formData, location, selected_address_id: null, new_address: {}, showNewAddressForm: false });
        }
    };

    // Handle image upload
    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        const totalImages = imagePreviews.length + files.length;

        if (totalImages > 3) {
            toast.error("You can only upload up to 3 images.");
            return;
        }

        const imageUrls = files.map((file) => URL.createObjectURL(file));

        // Store image files in formData (for submitting to backend) and also create preview links
        setImagePreviews((prevImages) => [...prevImages, ...imageUrls]);

        setFormData({
            ...formData,
            reference_images: [...(formData.reference_images || []), ...files],
        });
    };

    // Handle remove image
    const handleRemoveImage = (index) => {
        const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
        const updatedImages = formData.reference_images.filter((_, i) => i !== index);

        setImagePreviews(updatedPreviews);
        setFormData({
            ...formData,
            reference_images: updatedImages
        });
    };

    // Handle address selection
    const handleAddressSelect = (addressId) => {
        setFormData({ ...formData, selected_address_id: addressId, new_address: {}, showNewAddressForm: false });
    };

    return (
        <div className="p-6">
            <h2 className="text-primary text-3xl font-bold mb-6 text-center">Let Us Know More</h2>
            <p className="text-gray-600 text-center mb-6">
                Your comfort and satisfaction are our priority! Share any additional details or preferences, and let us know your preferred service location.
            </p>

            {/* Additional Details Textarea */}
            <div className="mb-8">
                <label
                    className="block text-primary font-semibold mb-3 text-lg"
                    htmlFor="additional_info"
                >
                    Any Special Instructions?
                </label>
                <textarea
                    id="additional_info"
                    rows="4"
                    className="w-full p-4 border border-gray-300 rounded-lg bg-bodyMain text-dark focus:ring-2 focus:ring-primary focus:outline-none"
                    placeholder="e.g., Please bring extra nail polish remover."
                    value={formData.additional_info || ''}
                    onChange={(e) => setFormData({
                        ...formData,
                        additional_info: e.target.value
                    })}
                ></textarea>
            </div>

            {/* Service Location Selection */}
            <div className="mb-8">
                <h3 className="text-primary font-semibold text-lg mb-4">Where Should We Serve You?</h3>
                <div className="flex flex-wrap gap-4">
                    <button
                        type="button"
                        className={`w-full sm:w-auto px-6 py-3 rounded-lg font-medium transition-all duration-300 ${formData.location === 'home'
                            ? 'bg-primary text-white shadow-lg'
                            : 'bg-bodyMain text-dark border hover:border-primary hover:shadow-md'
                            }`}
                        onClick={() => handleLocationChange('home')}
                    >
                        Home Service
                    </button>
                    <button
                        type="button"
                        className={`w-full sm:w-auto px-6 py-3 rounded-lg font-medium transition-all duration-300 ${formData.location === 'salon'
                            ? 'bg-primary text-white shadow-lg'
                            : 'bg-bodyMain text-dark border hover:border-primary hover:shadow-md'
                            }`}
                        onClick={() => handleLocationChange('salon')}
                    >
                        In-Salon
                    </button>
                </div>
            </div>

            {formData.location === 'home' && (
                <div className="mb-8">
                    <h3 className="text-primary font-semibold text-lg mb-4">Select Your Address</h3>

                    {!formData.showNewAddressForm && (
                        <>
                            {userAddresses.map((address) => (
                                <div
                                    key={address.id}
                                    className={`p-4 my-4 border rounded-lg cursor-pointer ${formData.selected_address_id === address.id ? 'bg-primary text-white' : 'bg-bodyMain'}`}
                                    onClick={() => handleAddressSelect(address.id)}
                                >
                                    <p>{address.street_address}</p>
                                    <p>{address.city}, {address.postal_code}</p>
                                </div>
                            ))}

                            <button
                                type='button'
                                onClick={() => {
                                    setFormData({
                                        ...formData,
                                        showNewAddressForm: true,
                                    });
                                }}
                                className="mt-4 text-primary underline"
                            >
                                + Add New Address
                            </button>
                        </>
                    )}


                    {formData.showNewAddressForm && (
                        <div className="mt-4 p-4 border rounded-lg">
                            <h4 className="font-semibold text-primary mb-2">Add New Address</h4>
                            <div className="grid grid-cols-2 gap-6">
                                <input type="text" name="street_address" placeholder="Street Address" value={formData.new_address?.street_address} onChange={handleInputAddressChange} className="w-full p-2 border" />
                                <input type="text" name="apartment_number" placeholder="Apartment Number" value={formData.new_address?.apartment_number} onChange={handleInputAddressChange} className="w-full p-2 border" />
                                <input type="text" name="city" placeholder="City" value={formData.new_address?.city} onChange={handleInputAddressChange} className="w-full p-2 border" />
                                <input type="text" name="postal_code" placeholder="Postal Code" value={formData.new_address?.postal_code} onChange={handleInputAddressChange} className="w-full p-2 border" />
                                <textarea name="additional_notes" placeholder="Additional Notes" value={formData.new_address?.additional_notes} onChange={handleInputAddressChange} className="w-full p-2 border"></textarea>
                            </div>
                            <button
                                type='button'
                                onClick={() => setFormData({
                                    ...formData,
                                    showNewAddressForm: false,
                                })}
                                className="mt-4 text-primary underline"
                            >
                                Existing Address
                            </button>
                        </div>
                    )}
                </div>
            )}

            {/* Image Upload Section */}
            <div className="mb-8">
                <h3 className="text-primary font-semibold text-lg mb-4">Reference Images</h3>
                <div className="bg-bodyMain p-4 rounded-lg ">
                    <label
                        htmlFor="reference_images"
                        className="block text-primary font-semibold mb-3"
                    >
                        Upload Reference Images
                    </label>

                    <input
                        type="file"
                        id="reference_images"
                        accept="image/*"
                        multiple
                        onChange={handleImageUpload}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-primary focus:ring-2 focus:ring-primary focus:outline-none shadow-inner"
                    />

                    {/* Image Previews */}
                    {formData.reference_images?.length > 0 && (
                        <div className="mt-4 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-4">
                            {formData.reference_images.map((file, index) => (
                                <div key={index} className="relative w-full h-24">
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt={`Reference ${index + 1}`}
                                        className="w-full h-full object-cover rounded-lg"
                                    />
                                    <button
                                        type='button'
                                        onClick={() => handleRemoveImage(index)}
                                        className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded-full text-sm"
                                    >
                                        ✕
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default AdditionalDetails;
