import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintBrush, faPaw } from '@fortawesome/free-solid-svg-icons';

const LogoutConfirmation = ({ isOpen, onConfirm, onClose }) => {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-lg" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-bodyMain p-6 text-center align-center shadow-xl transition-all">
                                <div className="flex flex-col items-center">
                                    {/* Icon */}
                                    <div className="text-bodyMain bg-primary rounded-full p-4 mb-4 shadow-lg">
                                        <FontAwesomeIcon icon={faPaintBrush} className="text-4xl text-bodyMain" />
                                    </div>

                                    {/* Title */}
                                    <Dialog.Title
                                        as="h3"
                                        className="text-2xl font-bold text-primary mb-2"
                                    >
                                        Are you sure you want to log out?
                                    </Dialog.Title>

                                    {/* Decorative Subtitle */}
                                    <p className="text-sm text-secondary italic mb-4">
                                        We’ll miss you! ✨
                                    </p>

                                    {/* Buttons */}
                                    <div className="flex justify-center space-x-3 w-full mt-3">
                                        <button
                                            onClick={onClose}
                                            className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-accent transition duration-200"
                                        >
                                            Stay
                                        </button>
                                        <button
                                            onClick={onConfirm}
                                            className="bg-gradient-to-r from-red-500 to-red-600 text-white px-4 py-2 rounded-lg hover:from-red-600 hover:to-red-700 transition duration-200"
                                        >
                                            Log Out
                                        </button>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default LogoutConfirmation;
