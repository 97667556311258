import React, { useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faSave,
  faWallet,
  faCrown,
  faPlusCircle,
  faToggleOn,
  faToggleOff,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { UserContext } from "../../context/UserContext";
import api from "../../api/api";
import AddressManager from "./AddressManager";
import { Link } from "react-router-dom";
import { routes } from "../../config/utility";

const UserProfile = () => {
  const { user, updateContext } = useContext(UserContext); // Access UserContext
  const [userData, setUserData] = useState({
    name: user?.name || "",
    phone: user?.phone || "",
    profilePhoto: user?.profile_photo || "https://via.placeholder.com/150", // Default placeholder
    walletBalance: user?.wallet_balance || 0,
    membership: user?.membership || null,
    membership: user?.membership || null,
    autoRenew: user?.auto_renew || false,
  });

  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [newPhoto, setNewPhoto] = useState(null); // Local state for photo upload
  const [loading, setLoading] = useState(false);

  // Handle Photo Change
  const handlePhotoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profile_photo", file);
      formData.append("_method", "PUT");

      try {
        setLoading(true);
        const response = await api.post(`/user/update`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        // Update UI optimistically
        setNewPhoto(URL.createObjectURL(file));
        setUserData((prevData) => ({
          ...prevData,
          profilePhoto: response.data.profile_photo,
        }));

        // Sync with UserContext
        updateContext();

        toast.success("Profile photo updated!");
      } catch (error) {
        console.error("Error updating photo:", error);
        toast.error("Failed to update profile photo.");
      } finally {
        setLoading(false);
      }
    }
  };

  // Handle Name Change
  const handleNameChange = (e) => {
    setUserData((prevData) => ({
      ...prevData,
      name: e.target.value,
    }));
  };

  // Handle Phone Change
  const handlePhoneChange = (phone) => {
    setUserData((prevData) => ({
      ...prevData,
      phone,
    }));
  };

  // Handle Auto-Renew Toggle
  const toggleAutoRenew = async () => {
    try {
      setLoading(true);
      toast.info("Auto-renewal Comming Soon!");

      // const response = await api.post("/user/toggle-auto-renew", {
      //   auto_renew: !userData.autoRenew,
      // });

      // setUserData((prevData) => ({
      //   ...prevData,
      //   autoRenew: response.data.auto_renew,
      // }));

      // updateContext();
      // toast.success("Auto-renewal updated!");
    } catch (error) {
      console.error("Error updating auto-renew:", error);
      toast.error("Failed to update auto-renewal.");
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = (e) => {
    setPasswordData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangePassword = async () => {
    const { currentPassword, newPassword, confirmPassword } = passwordData;

    if (newPassword !== confirmPassword) {
      toast.error("New Password and Confirm Password do not match!");
      return;
    }

    try {
      // Call your API to update the password
      const response = await api.post("/user/update", {
        _method: "PUT",
        current_password: currentPassword,
        new_password: newPassword,
        new_password_confirmation: confirmPassword,
      });

      toast.success("Password changed successfully!");
      setPasswordData({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      toast.error("Failed to change password. Please try again.");
    }
  };

  // Save Profile Changes
  const handleSave = async () => {
    try {
      setLoading(true);

      // Update name and phone on the server
      const response = await api.post("/user/update", {
        _method: "PUT",
        editProfile: true,
        name: userData.name,
        phone: userData.phone,
      });

      setUserData((prevData) => ({
        ...prevData,
        name: response.data.name,
        phone: response.data.phone,
      }));

      updateContext();
      toast.success("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile.");
    } finally {
      setLoading(false);
    }
  };



  return (
    <div className="min-h-screen grid grid-cols-1 lg:grid-cols-6 gap-2 bg-bodyMain  ">
      {/* Left Section: Quick Navigation */}
      <div className="hidden lg:col-span-1 lg:flex lg:flex-col align-middle space-y-6 w-full max-w-full lg:max-w-xs rounded-lg lg:h-screen  lg:sticky lg:top-4">
        {/* Upcoming Appointments */}
        <div className="bg-gradient-to-br from-secondary to-accent text-white  p-6 rounded-3xl shadow-lg">
          {user.paid ? (
            <div className="text-center">
              <h3 className="text-xl font-bold">Upcoming Appointments</h3>
              <ul className="mt-4 space-y-2">
                <li>
                  <span className="text-sm">Hair Treatment</span>
                  <p className="text-sm text-light">Nov 25, 2024 - 2:30 PM</p>
                </li>
                <li>
                  <span className="text-sm">Manicure</span>
                  <p className="text-sm text-light">Nov 27, 2024 - 11:00 AM</p>
                </li>
                <li>
                  <span className="text-sm">Facial</span>
                  <p className="text-sm text-light">Dec 1, 2024 - 5:00 PM</p>
                </li>
              </ul>
              <button className="mt-4 w-full py-2 bg-white text-primary rounded-lg font-semibold hover:bg-light transition">
                View All Appointments
              </button>
            </div>
          ) : (
            <div className="text-center">
              <h3 className="text-xl font-bold">No Appointments Yet! </h3>
              <p className="mt-4 text-sm">
                Transform your look today! 💎 Get priority booking and exclusive
                salon services by becoming a member. Don’t miss out on the
                luxury experience you deserve!
              </p>
              <Link to={routes.home}>

                <button className="mt-4 w-full py-2 bg-white text-primary rounded-lg font-semibold hover:bg-light transition">
                  Get Membership Now!
                </button>
              </Link>
            </div>
          )}
        </div>

        {user.member_card && (
          <div className="bg-gradient-to-br from-green-500 to-teal-500 text-white p-6 rounded-3xl shadow-lg">
            <h3 className="text-xl font-bold">Refer a Friend</h3>
            <p className="mt-4 text-sm">
              Invite your friends and earn loyalty points for every successful
              referral!
            </p>
            <button className="mt-4 w-full py-2 bg-white text-green-600 rounded-lg font-semibold hover:bg-green-100 transition">
              Refer Now
            </button>
          </div>
        )}

        {/* Loyalty Points */}
        {/* <div className="bg-white p-6 rounded-3xl shadow-lg">
          <h3 className="text-xl font-bold text-primary">Loyalty Points</h3>
          <p className="text-3xl font-bold text-accent mt-4">150</p>
          <div className="w-full bg-gray-200 h-2 rounded-full mt-4">
            <div className="bg-gradient-to-r from-primary to-secondary h-2 rounded-full w-3/4"></div>
          </div>
          <p className="text-sm text-gray-600 mt-2">
            Earn 50 more points for a free service!
          </p>
          <button className="mt-4 w-full py-2 bg-gradient-to-r from-primary to-accent text-white rounded-lg font-semibold hover:scale-105 transition">
            Learn More
          </button>
        </div> */}
      </div>

      {/* Main Section: Profile Details */}
      <div className="lg:col-span-4  flex-grow  lg:p-6 overflow-y-auto">
        {/* Header */}
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-primary">
            Welcome, <span className="text-accent">{userData.name}!</span>
          </h1>
          <p className="text-lg text-gray-600">
            Manage your profile, track membership, and enjoy personalized care!
          </p>
        </div>

        {/* Profile Cards */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Profile Photo */}
          <div className="bg-white p-8 rounded-3xl shadow-lg flex flex-col items-center justify-center text-center">
            {/* Profile Picture */}
            <div className="relative w-36 h-36 mb-4">
              <img
                src={newPhoto || userData.profilePhoto}
                alt="Profile"
                className="w-36 h-36 object-cover rounded-full shadow-md border-4 border-accent"
              />
              <label
                htmlFor="photo-upload"
                className="absolute bottom-0 right-0 bg-gradient-to-r from-primary to-accent p-3 rounded-full text-white shadow-md cursor-pointer"
              >
                <FontAwesomeIcon icon={faCamera} />
                <input
                  id="photo-upload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handlePhotoChange}
                />
              </label>
            </div>

            {/* User Name */}
            <h3 className="text-2xl font-bold text-primary">{userData.name}</h3>
          </div>

          {/* Membership Status */}
          <div className="bg-gradient-to-br from-accent to-primary text-white p-8 rounded-3xl shadow-lg">
            <h3 className="text-xl font-bold mb-4">Membership Status</h3>

            {userData.membership ? (
              <>
                {/* Membership Active */}
                <div className="space-y-4">
                  <p className="text-3xl font-bold flex items-center">
                    <FontAwesomeIcon
                      icon={faCrown}
                      className="mr-2 text-white"
                    />
                    {userData.membership.name}
                  </p>
                  <p className="text-sm mt-2">
                    Expires on:{" "}
                    <span className="text-2xl font-semibold">
                      {userData.membership.end_date}
                    </span>
                  </p>
                  <p className="text-sm">
                    Booking per month:{" "}
                    <span className="text-2xl font-semibold">
                      {userData.membership.permissions?.booking_limit || 0}
                    </span>
                  </p>
                  <p className="text-sm">
                    <span className="text-2xl font-semibold">
                      You are 1 out of only{" "}
                      {userData.membership.permissions?.membership_limit || 0}{" "}
                      members!
                    </span>
                  </p>

                  {/* Auto-Renewal Toggle */}
                  <div className="flex items-center justify-between mt-4">
                    <span>Auto-Renewal</span>
                    <button
                      onClick={toggleAutoRenew}
                      className="focus:outline-none"
                    >
                      <FontAwesomeIcon
                        icon={
                          userData.membership.auto_renew
                            ? faToggleOn
                            : faToggleOff
                        }
                        className={`text-3xl ${userData.membership.auto_renew
                          ? "text-green-400"
                          : "text-gray-300"
                          }`}
                      />
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* No Membership */}
                <div className="flex flex-col items-center space-y-4">
                  <FontAwesomeIcon icon={faLock} className="text-6xl mb-4" />
                  <p className="text-lg font-semibold">
                    You don't have an active membership.
                  </p>
                  <p className="text-sm">
                    Unlock exclusive benefits and indulge in luxury with our
                    membership plans.
                  </p>

                </div>
              </>
            )}
          </div>
        </div>

        {/* Edit Form */}
        <div className="bg-white p-8 rounded-3xl shadow-lg mt-8">
          <h2 className="text-3xl font-bold text-primary mb-6 text-center">
            Edit Profile
          </h2>

          <div className="grid grid-cols-1 gap-6">

            {/* Email (Read-Only) */}
            <div>
              <label className="block text-gray-600 font-semibold">Email</label>
              <input
                type="email"
                value={user.email}
                readOnly
                className="w-full px-4 py-2 rounded-lg border bg-gray-100 text-gray-500 cursor-not-allowed"
                placeholder="Your email address"
              />
            </div>

            {/* Emirates ID (Read-Only) */}
            <div>
              <label className="block text-gray-600 font-semibold">Emirates ID</label>
              <input
                type="email"
                value={user.emirates_id}
                readOnly
                className="w-full px-4 py-2 rounded-lg border bg-gray-100 text-gray-500 cursor-not-allowed"
                placeholder="Your email address"
              />
            </div>


            {/* Username (Read-Only) */}
            <div>
              <label className="block text-gray-600 font-semibold">
                Username
              </label>
              <input
                type="text"
                value={user.username}
                readOnly
                className="w-full px-4 py-2 rounded-lg border bg-gray-100 text-gray-500 cursor-not-allowed"
                placeholder="Your username"
              />
            </div>

            {/* Full Name */}
            <div>
              <label className="block text-gray-600 font-semibold">
                Full Name
              </label>
              <input
                type="text"
                value={userData.name}
                onChange={handleNameChange}
                className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary"
                placeholder="Enter your name"
              />
            </div>

            {/* Phone Number */}
            <div>
              <label className="block text-gray-600 font-semibold">Phone</label>
              <PhoneInput
                country={"ae"} // Default to UAE
                value={userData.phone}
                onChange={handlePhoneChange}
                inputStyle={{
                  width: "90%",
                  padding: "14px 20px",
                  border: "1px solid #d1d5db",
                  borderRadius: "8px",
                  color: "#4b5563",
                  marginLeft: "45px", // Create space between flag and number input
                  height: "40px", // Increase the overall height of the input for a spacious look
                }}
                buttonStyle={{
                  borderRadius: "8px 0 0 8px",
                  paddingRight: "10px", // Ensure the button has enough padding inside
                  height: "40px", // Match the height of the input
                }}
                inputClass="focus:ring-purple-500 focus:border-purple-500"
                dropdownClass="phone-dropdown"
                searchPlaceholder="Search for country" // Placeholder for search input
                enableSearch={true} // Enable search functionality
                searchStyle={{
                  padding: "10px 12px",
                  fontSize: "14px",
                  border: "1px solid #d1d5db",
                  borderRadius: "8px",
                }}
              />
            </div>
            {/* Change Password Section */}
            <div className="mt-4 bg-gray-50 p-6 rounded-lg shadow-inner">
              <h3 className="text-xl font-semibold text-primary mb-4">
                Change Password
              </h3>
              <div className="grid grid-cols-1 gap-4">
                {/* Current Password */}
                <div>
                  <label className="block text-gray-600 font-semibold">
                    Current Password
                  </label>
                  <input
                    type="password"
                    name="currentPassword"
                    value={passwordData.currentPassword}
                    onChange={handlePasswordChange}
                    className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary"
                    placeholder="Enter current password"
                  />
                </div>

                {/* New Password */}
                <div>
                  <label className="block text-gray-600 font-semibold">
                    New Password
                  </label>
                  <input
                    type="password"
                    name="newPassword"
                    value={passwordData.newPassword}
                    onChange={handlePasswordChange}
                    className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary"
                    placeholder="Enter new password"
                  />
                </div>

                {/* Confirm New Password */}
                <div>
                  <label className="block text-gray-600 font-semibold">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={passwordData.confirmPassword}
                    onChange={handlePasswordChange}
                    className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-primary"
                    placeholder="Re-enter new password"
                  />
                </div>

                {/* Save Password Button */}
                <button
                  onClick={handleChangePassword}
                  className="w-full py-3 bg-light text-primary rounded-lg font-bold transition-transform transform hover:scale-105"
                >
                  <FontAwesomeIcon icon={faLock} /> Change Password
                </button>
              </div>
            </div>

            {/* Save Profile Changes */}
            <button
              onClick={handleSave}
              className="w-full py-3 bg-primary text-white rounded-lg font-bold transition-transform transform hover:scale-105"
            >
              <FontAwesomeIcon icon={faSave} /> Save Changes
            </button>
          </div>
        </div>

        <AddressManager user={user} />

      </div>

      {/* Right Section: Offers */}
      <div className="lg:col-span-1 flex flex-col align-middle space-y-6 w-full max-w-xs lg:h-screen lg:sticky top-0">
        {user.member_card ? (
          <div className="bg-gradient-to-br from-gray-100 to-gray-200 rounded-3xl shadow-lg hover:shadow-xl transition-all p-6">
            {/* Card Header */}
            <div className="flex justify-between items-center mb-4 ">
              <h3 className="text-xl font-bold text-primary uppercase">Member Card</h3>
              <FontAwesomeIcon icon={faCrown} className="text-accent text-3xl" />
            </div>

            {/* Member Card Image */}
            <a
              href={user.member_card}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={user.member_card}
                alt="Exclusive Member Card"
                className="w-full rounded-xl shadow-md border-2 border-primary mb-4 object-cover hover:scale-105  transition-all"
              />
            </a>

            {/* User Details */}
            <div className="mt-4">
              <p className="text-lg text-gray-700 font-semibold">
                {user.name}
              </p>
              <p className="text-sm text-gray-500">Member since {user.join_at}</p>
            </div>


          </div>
        ) : (
            <div className="bg-gradient-to-br from-green-500 to-teal-500 text-white p-6 rounded-3xl shadow-lg">
              <h3 className="text-xl font-bold">Refer a Friend</h3>
              <p className="mt-4 text-sm">
                Invite your friends and earn loyalty points for every successful
                referral!
              </p>
              <button className="mt-4 w-full py-2 bg-white text-green-600 rounded-lg font-semibold hover:bg-green-100 transition">
                Refer Now
              </button>
            </div>
        )}
      </div>


    </div>
  );
};

export default UserProfile;
