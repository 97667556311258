import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit, faPlus, faSyncAlt, faSearch, faHome, faStore, faEye, faMapMarkerAlt, faClock, faCalendarAlt, faCheckCircle, faCalendarDay, faArrowRight, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../api/api';
import { Dialog, DialogBackdrop, Transition } from '@headlessui/react';
import { convertToAmPm, formatPrice } from '../../config/utility';
import { StepWizard } from '../../components/StepWizard';
import { motion } from "framer-motion";
import LoadingLogo from '../../components/Loading';


const StaffBooking = ({ user }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editData, setEditData] = useState(null);
    const [isShowModalOpen, setIsShowModalOpen] = useState(false);
    const [filter, setFilter] = useState('today'); // New filter state
    const [currentPage, setCurrentPage] = useState(1);
    const [meta, setMeta] = useState({
        total: 0,
        per_page: 10,
        current_page: 1,
        last_page: 1,
        from: 1,
        to: 1,
    });

    useEffect(() => {
        fetchData();
    }, [filter, currentPage]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/bookings`, {
                params: {
                    filter, // Pass the selected filter to the API
                    page: currentPage,
                },
            });
            setData(response.data);
            setMeta(response.meta);
            setLoading(false);
        } catch (error) {
            toast.error('Failed to fetch bookings.');
            setLoading(false);
        }
    };

    const handleShow = (booking) => {
        setEditData(booking);
        setIsShowModalOpen(true);
    };

    const handleShowCancel = () => {
        setIsShowModalOpen(false);
    };

    const handleNextPage = () => {
        if (meta.current_page < meta.last_page) {
            setCurrentPage(meta.current_page + 1);
        }
    };

    const handlePrevPage = () => {
        if (meta.current_page > 1) {
            setCurrentPage(meta.current_page - 1);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= meta.last_page; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => setCurrentPage(i)}
                    className={`px-3 py-1 rounded-lg border border-primary ${i === meta.current_page ? 'bg-primary text-white' : 'bg-white text-gray-700'
                        } hover:bg-light hover:text-primary mx-1`}
                >
                    {i}
                </button>
            );
        }
        return pages;
    };

    if (loading) {
        return <LoadingLogo />;
    }

    return (
        <div className="mx-auto px-4 py-6">
            {/* Header */}
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-primary text-3xl font-bold">Bookings</h1>
            </div>

            {/* Filter Buttons */}
            <div className="flex space-x-4 mb-6">
                {[
                    { name: 'All', value: 'all', icon: faCalendarDay },
                    { name: 'Today', value: 'today', icon: faClock },
                    { name: 'Upcoming', value: 'upcoming', icon: faCalendarDay },
                ].map((filterItem) => (
                    <button
                        key={filterItem.value}
                        onClick={() => setFilter(filterItem.value)}
                        className={`flex items-center space-x-2 px-4 py-2 rounded-full font-semibold text-sm shadow-md 
                            ${filter === filterItem.value
                                ? 'bg-gradient-to-r from-primary to-gradientEnd text-white'
                                : 'bg-light text-dark hover:bg-gradientEnd hover:text-white transition-all'
                            }`}
                    >
                        <FontAwesomeIcon icon={filterItem.icon} />
                        <span>{filterItem.name}</span>
                    </button>
                ))}
            </div>

            {/* Booking Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {data.length > 0 ? (
                    data.map((booking) => (
                        <div
                            key={booking.id}
                            className={`relative bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 ${booking.status === 'complete' ? 'line-through opacity-75' : ''
                                }`}
                        >
                            <div className="bg-gradient-to-r from-primary to-gradientEnd p-6  rounded-t-xl ">
                                <h2 className="text-white text-xl font-extrabold">
                                    {new Date(booking.day).toLocaleDateString('en-US', {
                                        weekday: 'long',
                                        day: 'numeric',
                                        month: 'long',
                                        year: 'numeric',
                                    })}
                                </h2>
                                <p className="text-white text-sm">
                                    {convertToAmPm(booking.start_time, true)} - {convertToAmPm(booking.end_time, true)}
                                </p>
                            </div>

                            <div className="absolute top-4 right-4">
                                <div
                                    className={`flex items-center space-x-2 p-2 px-3 rounded-full text-primary  bg-bodyMain
                                 
                                text-xs font-bold shadow-md`}
                                >
                                    {booking.home_service ? (
                                        <>
                                            <FontAwesomeIcon icon={faHome} />
                                            <span>Home Service</span>
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faStore} />
                                            <span>Salon Visit</span>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="p-6">
                                <button
                                    onClick={() => handleShow(booking)}
                                    className="w-full bg-gradient-to-r from-primary to-gradientEnd text-white px-6 py-3 rounded-full text-sm font-bold shadow-md hover:shadow-xl"
                                >
                                    <FontAwesomeIcon icon={faEye} className="mr-2" />
                                    View Booking Details
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="text-center col-span-full text-gray-500">
                        No bookings available.
                    </div>
                )}
            </div>

            {/* Pagination */}
            <div className="mt-4 flex justify-between items-center">
                <span className="text-gray-500">
                    Showing {meta.from} to {meta.to} of {meta.total} bookings
                </span>
                <div className="flex items-center space-x-2">
                    <button
                        onClick={handlePrevPage}
                        disabled={meta.current_page === 1}
                        className="bg-white px-4 py-2 rounded-lg border border-primary"
                    >
                        Previous
                    </button>
                    {renderPageNumbers()}
                    <button
                        onClick={handleNextPage}
                        disabled={meta.current_page === meta.last_page}
                        className="bg-white px-4 py-2 rounded-lg border border-primary"
                    >
                        Next
                    </button>
                </div>
            </div>

            {isShowModalOpen && (
                <ShowModal item={editData} onCancel={handleShowCancel} user={user} />
            )}
        </div>
    );
};

export default StaffBooking;


const steps = [
    { number: 1, label: "Select Service" },
    { number: 2, label: "Select Color" },
    { number: 3, label: "Additional Details" },
    { number: 4, label: "Select Day" },
    { number: 5, label: "Select Slot" },
    { number: 6, label: "Summary & Submit" },
];



export const ShowModal = ({ item, onCancel, user }) => {
    const [loading, setLoading] = useState(false);

    if (!item) return null;

    const isPrimaryStaff = item.primary_staff?.email === user.email;

    // Mark Booking as Completed
    const handleMarkAsCompleted = async () => {

        const isConfirmed = window.confirm(
            "Are you sure you want to mark this booking as completed? This action cannot be undone and ensures that the service was successfully delivered."
        );

        if (!isConfirmed) {
            return; // Exit if the user cancels
        }


        try {
            setLoading(true);

            await api.post(`/bookings/${item.id}`, {
                _method: "PUT",
                status: "complete",
            });

            toast.success("Booking marked as completed!");
            onCancel(); // Close the modal after marking as completed
        } catch (error) {
            console.error("Error marking booking as completed:", error);
            toast.error("Failed to mark booking as completed. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Transition appear show as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onCancel}>
                <Transition.Child
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-0 m-0 text-center">
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-0 text-left align-middle shadow-xl transition-all">

                                {/* Header */}
                                <div className="bg-gradient-to-r from-primary to-gradientEnd p-8 text-white rounded-t-2xl">
                                    <h2 className="text-3xl font-bold">Booking Overview</h2>
                                    <p className="text-sm">
                                        Here's a detailed summary of this booking. Let’s ensure this appointment is handled seamlessly!
                                    </p>
                                </div>



                                {/* Conversational Details */}
                                <div className="p-6 space-y-6">
                                    <div>
                                        <h3 className="text-xl font-semibold text-primary">Hello {user.name}!</h3>
                                        <p className="text-sm text-gray-600">
                                            This booking is scheduled with <strong>{item.user.name}</strong>. The session is planned for <strong>{new Date(item.day).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}</strong>, starting at <strong>{convertToAmPm(item.start_time, true)}</strong> and ending at <strong>{convertToAmPm(item.end_time, true)}</strong>. Here are the details:
                                        </p>
                                    </div>

                                    {/* Booking Information */}
                                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                        <div className="bg-light p-4 rounded-xl shadow-sm flex items-center">
                                            <FontAwesomeIcon icon={faCalendarAlt} className="text-primary mr-3" />
                                            <p className="text-dark">
                                                <strong>Date:</strong> {new Date(item.day).toLocaleDateString('en-US')}
                                            </p>
                                        </div>

                                        <div className="bg-light p-4 rounded-xl shadow-sm flex items-center">
                                            <FontAwesomeIcon icon={faClock} className="text-primary mr-3" />
                                            <p className="text-dark">
                                                <strong>Time Slot:</strong> {convertToAmPm(item.start_time, true)} - {convertToAmPm(item.end_time, true)}
                                            </p>
                                        </div>

                                        <div className="bg-light p-4 rounded-xl shadow-sm flex items-center">
                                            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-primary mr-3" />
                                            <p className="text-dark">
                                                <strong>Service:</strong> {item.home_service ? 'Home Service' : 'Salon Visit'}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Primary Staff Information */}
                                    <div className="p-6 bg-gray-100 rounded-xl shadow-sm">
                                        <p className="text-sm text-gray-600">
                                            <strong>Primary Staff:</strong> <span className="text-primary font-bold">{item.primary_staff?.name || "Not Assigned"}</span>
                                        </p>
                                        {isPrimaryStaff ? (
                                            <p className="mt-2 text-sm text-green-700 font-medium">
                                                You are the primary staff for this booking. You have the privilege to mark this booking as complete once the service is delivered.
                                            </p>
                                        ) : (
                                            <p className="mt-2 text-sm text-gray-600">
                                                The primary staff responsible for marking this booking as complete is <span className="text-primary font-bold">{item.primary_staff?.name || "Not Assigned"}</span>.
                                            </p>
                                        )}
                                    </div>


                                    {
                                        (item.home_service && item.total_price > 0) && (
                                            <div className="p-6 bg-gray-100 rounded-xl shadow-sm">
                                                <h3 className="text-lg font-bold text-dark mb-2">Service Charges</h3>

                                                <p className="text-sm text-gray-600">
                                                    <strong>Total Amount Payable:</strong>
                                                    <span className="text-primary font-bold text-xl ml-2">
                                                        {item.total_price ? `AED ${item.total_price.toFixed(2)}` : "Amount Not Available"}
                                                    </span>
                                                </p>

                                                <div className="mt-4 p-4 bg-white rounded-lg shadow-md">
                                                    <p className="text-sm text-gray-600">
                                                        The **total charges** for this booking are based on the selected services, applied taxes, and any additional fees. Please ensure that this amount is settled after the service has been completed.
                                                    </p>
                                                </div>

                                                <div className="mt-4">
                                                    {item.total_price ? (
                                                        <p className="mt-2 text-sm text-green-700 font-medium">
                                                            The total amount of <strong className="text-primary">AED {item.total_price.toFixed(2)}</strong> will be collected from the client upon completion of the service.
                                                        </p>
                                                    ) : (
                                                        <p className="mt-2 text-sm text-red-600 font-medium">
                                                            The total amount for this booking is not available at the moment. Please verify the service details.
                                                        </p>
                                                    )}
                                                </div>


                                            </div>
                                        )
                                    }




                                    {/* Client Details */}
                                    <h3 className="text-md font-bold text-dark m-0 p-0 ">Client</h3>
                                    <div className="flex items-center gap-6 bg-light p-6 rounded-xl shadow-md">
                                        <img
                                            src={item.user.profile_photo}
                                            alt="User Profile"
                                            className="w-20 h-20 rounded-full border-4 border-primary object-cover"
                                        />
                                        <div>
                                            <h4 className="text-lg font-bold text-dark">{item.user.name}</h4>
                                            <p className="text-sm text-gray-500">Emirates ID : {item.user.emirates_id}</p>
                                        </div>
                                    </div>


                                    {item.address && (
                                        <div className="">
                                            <h3 className="text-md font-bold text-dark mb-4">Client Address</h3>
                                            <div className="bg-light p-4 rounded-xl shadow-sm">
                                                <div className="flex items-center mb-2">
                                                    <FontAwesomeIcon icon={faHome} className="text-primary mr-2" />
                                                    <p className="text-dark">
                                                        <strong>Street:</strong> {item.address.street_address}
                                                    </p>
                                                </div>
                                                <div className="flex items-center mb-2">
                                                    <FontAwesomeIcon icon={faBuilding} className="text-primary mr-2" />
                                                    <p className="text-dark">
                                                        <strong>Apartment:</strong> {item.address.apartment_number}
                                                    </p>
                                                </div>
                                                <p className="text-dark">
                                                    <strong>City:</strong> {item.address.city} |{" "}
                                                    <strong>Postal Code:</strong> {item.address.postal_code}
                                                </p>
                                                <p className="text-gray-500 mt-2">
                                                    <strong>Additional Notes:</strong> {item.address.additional_notes}
                                                </p>
                                            </div>
                                        </div>
                                    )}

                                    <div className="space-y-4">
                                        <h3 className="text-md font-bold text-dark">Additional Info From Client</h3>
                                        <p className="text-dark bg-light p-4 rounded-lg shadow-sm">
                                            {item.additional_info || "No additional details provided"}
                                        </p>
                                    </div>


                                    {/* Assigned Staff */}
                                    <div>
                                        <h3 className="text-md font-bold text-dark mb-4">Assigned Staff</h3>
                                        <div className="flex gap-4">
                                            {item.assigned_staffs.map((staff) => (
                                                <div key={staff.id} className="flex items-center bg-light p-4 rounded-xl shadow-sm">
                                                    <img
                                                        src={staff.profile_photo}
                                                        alt={staff.name}
                                                        className="w-12 h-12 rounded-full border-2 border-primary object-cover"
                                                    />
                                                    <div className="ml-3">
                                                        <p className="font-bold">
                                                            {staff.email === user.email ? "YOU" : staff.name}
                                                        </p>
                                                        <p className="text-sm text-gray-500">{staff.email !== user.email && staff.email}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Services */}
                                    <div>
                                        <h3 className="text-md font-bold text-dark mb-4">Services for This Booking</h3>
                                        {item.services.map((service) => (
                                            <div key={service.id} className="bg-light p-4 rounded-xl shadow-sm mb-4">
                                                <h4 className="font-bold text-dark">{service.name}</h4>
                                                <p className="text-sm text-gray-500">
                                                    Duration: {service.duration_min}
                                                </p>
                                            </div>
                                        ))}
                                    </div>



                                    {/* Color Details */}
                                    <div>
                                        <h3 className="text-md font-bold text-dark mb-4">Selected Nail Art</h3>
                                        {item.color ? (
                                            <div className="flex items-center bg-light p-4 rounded-xl shadow-sm">
                                                <img
                                                    src={item.color.image}
                                                    alt={item.color.name}
                                                    className="w-16 h-16 rounded-full border-2 border-primary object-cover"
                                                />
                                                <div className="ml-4">
                                                    <p className="text-lg font-bold text-primary">{item.color.name}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <p className="text-sm text-gray-500">No nail art selected for this booking.</p>
                                        )}
                                    </div>

                                    {item.reference_images && item.reference_images.length > 0 && (
                                        <div className="">
                                            <h3 className="text-md font-bold text-dark mb-4">Reference Images Client Uploaded</h3>
                                            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                                                {item.reference_images.map((image) => (
                                                    <div
                                                        key={image.id}
                                                        className="rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-transform transform hover:scale-105"
                                                    >
                                                        <a
                                                            href={image.url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img
                                                                src={image.url}
                                                                alt={`Reference ${image.id}`}
                                                                className="w-full h-32 object-cover"
                                                            />
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}


                                </div>

                                {/* Footer */}
                                <div className="flex justify-between p-6">
                                    <button
                                        onClick={onCancel}
                                        className="bg-gray-500 text-white px-6 py-2 rounded-full hover:bg-gray-700 transition-all"
                                    >
                                        Close
                                    </button>

                                    {isPrimaryStaff && item.status != "complete" && (
                                        <button
                                            onClick={handleMarkAsCompleted}
                                            className={`px-6 py-2 rounded-full text-white font-bold ${loading ? "bg-gray-400 cursor-not-allowed" : "bg-primary hover:bg-gradientEnd"
                                                } transition-all`}
                                            disabled={loading}
                                        >
                                            {loading ? "Marking..." : "Mark as Completed"}
                                        </button>
                                    )}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};






