import React, { useState } from 'react';
import { motion } from 'framer-motion';

const ServiceSelector = ({ formData, setFormData, services }) => {
    const [selectedServices, setSelectedServices] = useState(formData.services || []);

    const handleServiceToggle = (serviceId) => {
        const isSelected = selectedServices.includes(serviceId);
        const updatedServices = isSelected
            ? selectedServices.filter((id) => id !== serviceId) 
            : [...selectedServices, serviceId]; 

        setSelectedServices(updatedServices);
        setFormData({ ...formData, services: updatedServices });
    };

    // Separate services into extra and non-extra
    const extraServices = services.filter((service) => service.is_extra === 1);
    const nonExtraServices = services.filter((service) => service.is_extra === 0);

    const renderService = (service) => {
        const isSelected = selectedServices.includes(service.id);

        return (
            <motion.div
                key={service.id}
                className={`p-5 rounded-lg cursor-pointer border-2 transition-all duration-300 shadow-md ${
                    isSelected
                        ? 'border-primary bg-primary text-bodyMain shadow-lg' 
                        : 'bg-bodyMain border-gray-200 text-dark hover:border-primary hover:shadow-lg'
                }`}
                whileHover={{
                    scale: 1.05,
                }}
                whileTap={{ scale: 0.95 }}
                onClick={() => handleServiceToggle(service.id)}
            >
                <div className="flex flex-col justify-between h-full">
                    <div>
                        {/* Service Name */}
                        <h3
                            className={`text-lg font-semibold mb-1 ${
                                isSelected ? 'text-bodyMain' : 'text-dark'
                            }`}
                        >
                            {service.name}
                        </h3>

                        {/* Duration */}
                        <p
                            className={`text-sm ${
                                isSelected ? 'text-bodyMain' : 'text-gray-500'
                            }`}
                        >
                            {service.duration_min}
                        </p>

                        {/* Price */}
                        {/* {service.price && (
                            <p
                                className={`text-md font-medium mt-2 ${
                                    isSelected ? 'text-bodyMain' : 'text-dark'
                                }`}
                            >
                                Price: <span className={`${isSelected ? 'text-bodyMain' : 'text-primary'}`}>${service.price}</span>
                            </p>
                        )} */}
                    </div>
                   
                </div>
            </motion.div>
        );
    };

    return (
        <div className="px-6 pb-6">
            <h2 className="text-primary text-4xl font-bold mb-4 text-center">Pamper Yourself with Our Services</h2>
            <p className="text-gradientEnd text-center mb-6">
                Transform your nails and indulge in a touch of luxury. Explore our services, from classic care to premium extras, and let us create the perfect look just for you!
            </p>

            {/* Non-Extra Services */}
            <div className="mb-8">
                <h3 className="text-primary text-2xl font-semibold pt-4 mb-2">Essential Nail Care</h3>
                <p className="text-sm text-gradientEnd mb-4">
                    The foundation of every flawless manicure or pedicure. Choose from these essential services to keep your nails looking healthy and beautiful.
                </p>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                    {nonExtraServices.map(renderService)}
                </div>
            </div>

            {/* Extra Services */}
            {extraServices.length > 0 && (
                <div>
                    <h3 className="text-primary text-2xl font-semibold pt-4 mb-2">Premium Enhancements</h3>
                    <p className="text-sm text-gradientEnd mb-4">
                        Take your nail game to the next level! Add these luxurious enhancements for a truly personalized and glamorous touch.
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                        {extraServices.map(renderService)}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ServiceSelector;
