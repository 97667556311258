import { useContext, useState } from "react";
import api from "../../api/api";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { routes } from "./../../config/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const { contextLogin, updateContext } = useContext(UserContext);
  const [identifier, setIdentifier] = useState(""); // Can be email, phone, or username
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // Track loading state
  const navigate = useNavigate();

  // Handle login
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      const response = await api.post("/login", { login: identifier, password });
      contextLogin(response.data);
      updateContext();
      navigate(routes.dashboard); // Navigate to the dashboard
    } catch (error) {
      console.error("Login error:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  // Navigate to Register
  const handleNavigateToRegister = () => {
    navigate(routes.registration);
  };

  // Navigate to Forgot Password
  const handleNavigateToForgotPassword = () => {
    navigate(routes.forgot_password);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-bodyMain to-primary animate-fade-in px-4">
      <div className="bg-white p-10 rounded-3xl shadow-lg w-full max-w-lg transform transition duration-500 ease-in-out hover:shadow-2xl hover:scale-105 animate-slide-up">
        <div className="text-center mb-8">
          <img
            src="/assets/logo.png"
            alt="Logo"
            className="w-32 mx-auto"
          />

          <h2 className="text-4xl font-extrabold text-primary first-line:tracking-tight animate-fade-in-down">
            Welcome Back, Beautiful! 
          </h2>
          <p className="text-gray-600 mt-2 text-sm animate-fade-in-down">
            Let’s get you polished up! Enter your credentials to access your salon account.
          </p>
        </div>

        {/* Login Form */}
        <form onSubmit={handleLogin} className="space-y-6">
          <div className="relative">
            <label htmlFor="identifier" className="block text-gray-600 font-medium mb-1 animate-fade-in-left">
              Login ID
            </label>
            <input
              type="text" // Accepts all formats (email, phone, or username)
              id="identifier"
              className="input w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-primary transition-transform focus:scale-105 shadow-sm animate-fade-in-left"
              placeholder="Email, phone, or username"
              required
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
            />
          </div>

          <div className="relative">
            <label htmlFor="password" className="block text-gray-600 font-medium mb-1 animate-fade-in-left">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="input w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-primary transition-transform focus:scale-105 shadow-sm animate-fade-in-left"
              placeholder="Enter your password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <button
            type="submit"
            disabled={loading} // Disable the button while loading
            className={`w-full py-3 text-white bg-primary rounded-lg shadow-lg transition-transform transform duration-300 ease-in-out ${
              loading ? "opacity-75 cursor-not-allowed" : "hover:scale-105"
            }`}
          >
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
            ) : (
              "Login"
            )}
          </button>
        </form>

        {/* Additional Actions */}
        <div className="mt-6 text-center flex justify-between space-x-6">
          <a
            href="#"
            onClick={handleNavigateToForgotPassword}
            className="text-primary text-sm hover:underline transition duration-200 ease-in-out"
          >
            Forgot your password?
          </a>

          <a
            href="#"
            onClick={handleNavigateToRegister}
            className="text-primary text-sm hover:underline transition duration-200 ease-in-out"
          >
            New here? Register 
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
