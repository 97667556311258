import React from "react";
import { motion } from "framer-motion";
import { APP_NAME } from "../config/constants";

const LoadingLogo = () => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-30 backdrop-blur-md">
      {/* Rotating Rings */}
      <motion.div
        className="absolute w-80 h-80 rounded-full border-[8px] border-t-primary border-r-secondary border-b-accent border-l-gray-200"
        animate={{ rotate: [0, 360] }}
        transition={{
          duration: 6,
          ease: "linear",
          repeat: Infinity,
        }}
      />
      <motion.div
        className="absolute w-[300px] h-[300px] rounded-full border-[6px] border-t-accent border-r-primary border-b-secondary border-l-gray-300 opacity-70"
        animate={{ rotate: [360, 0] }}
        transition={{
          duration: 8,
          ease: "linear",
          repeat: Infinity,
        }}
      />

      {/* Pulsing Background */}
      <motion.div
        className="absolute w-[500px] h-[500px] rounded-full bg-gradient-to-br from-primary to-secondary blur-3xl opacity-20"
        animate={{ scale: [1, 1.2, 1] }}
        transition={{
          duration: 3,
          ease: "easeInOut",
          repeat: Infinity,
        }}
      />

      {/* Centered Content */}
      <motion.div
        className="flex flex-col items-center justify-center z-10"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 1.5,
          ease: "easeInOut",
        }}
      >
        {/* Animated App Name */}
        <motion.div
          className="text-gray-800 text-4xl md:text-6xl font-bold uppercase tracking-widest"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          {APP_NAME}
        </motion.div>
        <motion.div
          className="mt-4 text-gray-600 text-lg md:text-xl italic tracking-wider"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 1.5,
            delay: 0.5,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          Loading, Please wait...
        </motion.div>
      </motion.div>
    </div>
  );
};

export default LoadingLogo;
