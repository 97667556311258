import React, { useContext, useEffect, useState } from "react";
import api from "../../api/api";
import { CURRENCY_CODE } from "../../config/constants";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { UserContext } from "../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheckCircle, faCreditCard, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const ApplyForMembership = () => {
  const [step, setStep] = useState(1); // Multi-step control
  const [memberships, setMemberships] = useState([]); // Membership data
  const [selectedMembership, setSelectedMembership] = useState(null); // Selected membership
  const [loading, setLoading] = useState(true);

  // Fetch memberships on mount
  useEffect(() => {
    const fetchMemberships = async () => {
      try {
        const response = await api.get("/memberships?getAllAtOnce=true");
        setMemberships(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching memberships:", error);
        setLoading(false);
      }
    };

    fetchMemberships();
  }, []);

  // Handle membership selection
  const handleSelectMembership = (membership) => {
    setSelectedMembership(membership);
    setStep(2); // Move to the payment step
  };

  return (
    <div className="flex flex-col items-center justify-center px-4 py-8">
      {loading ? (
        <p className="text-dark text-lg font-bold">Loading memberships...</p>
      ) : (
        <>
          {step === 1 && (
            <Step1
              memberships={memberships}
              onSelectMembership={handleSelectMembership}
            />
          )}
          {step === 2 && (
            <Step2
              membership={selectedMembership}
              onNext={() => setStep(3)}
              onBack={() => setStep(1)}
            />
          )}
          {step === 3 && <Step3 membership={selectedMembership} />}
        </>
      )}
    </div>
  );
};

export default ApplyForMembership;

export const Step1 = ({ memberships, onSelectMembership }) => {
  return (
    <div className="w-full space-y-12">
   
      <div className="grid gap-12 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2">
        {memberships.map((membership) => (
          <div
            key={membership.id}
            className="bg-bodyMain rounded-3xl shadow-lg p-8 flex flex-col justify-between hover:shadow-primary transition-transform transform hover:scale-105 relative"
          >
            {/* Ribbon for Best Value */}
            {membership.name === "Dyo Membership" && (
              <span className="absolute top-0 right-0 bg-accent text-white text-sm font-bold py-2 px-4 rounded-bl-3xl shadow-lg">
                Best Value
              </span>
            )}

            {/* Membership Details */}
            <div>
              <h3 className="text-2xl font-bold text-accent mb-4">
                {membership.name}
              </h3>
              <p className="text-dark text-lg font-semibold mb-4">
                AED {membership.price} / month
              </p>
              <p className="text-body whitespace-pre-line text-sm mb-6">
                {membership.description}
              </p>
              <p className="text-sm text-body">
                <strong>Booking Limit:</strong>{" "}
                {membership.permissions?.booking_limit || 0} free bookings/month
              </p>
            </div>

            {/* Button */}
            <button
              className="mt-6 px-6 py-2  bg-primary text-white rounded-lg shadow hover:from-dark hover:to-primary hover:scale-105 transition-transform"
              onClick={() => onSelectMembership(membership)}
            >
              Treat Yourself with {membership.name}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};





export const Step2 = ({ membership, onNext, onBack }) => {
  const { updateContext, user } = useContext(UserContext);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Address Form State
  const [address, setAddress] = useState({
    fullName: user?.name || '',
    street_address: '',
    apartment_number: '',
    city: '',
    postal_code: '',
    additional_notes: '',
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle Payment Submission
  const handlePayment = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validate required address fields
    if (!address.street_address || !address.city) {
      setError("Please fill in all required fields (Street Address, City).");
      setLoading(false);
      return;
    }

    if (!stripe || !elements) {
      setError("Stripe has not been loaded yet.");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        setError(error.message);
        setLoading(false);
        return;
      }

      const response = await api.post("/subscribe", {
        payment_method: paymentMethod.id,
        membership_id: membership.id,
        address,
      });

      if (response) {
        setError("");
        updateContext();
        onNext(); // Proceed to the thank-you page
      }
    } catch (err) {
      console.error("Payment error:", err);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full bg-bodyMain rounded-3xl shadow-xl p-10">
      {/* Title */}
      <h2 className="text-4xl font-extrabold text-primary mb-6 text-center">
        ✨ Step Into a World of Elegance & Indulgence ✨
      </h2>
      <p className="text-body mb-6 text-lg text-center">
        You're just moments away from unlocking your <strong>{membership.name}</strong> membership.
        Share the details of your preferred service location and complete your payment to begin your journey of
        luxury, relaxation, and personalized care. 🌸
      </p>
      {/* Address Form */}
      <form onSubmit={handlePayment} className="space-y-6">

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Full Name */}
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Full Name</label>
            <input
              type="text"
              name="fullName"
              value={address.fullName}
              onChange={handleInputChange}
              placeholder="Your Name (Optional)"
              className="w-full p-4 bg-white rounded-lg border border-primary focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>

          {/* Street Address */}
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Where will we pamper you? <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="street_address"
              value={address.street_address}
              onChange={handleInputChange}
              placeholder="e.g., 123 Palm Jumeirah, Dubai"
              className="w-full p-4 bg-white rounded-lg border border-primary focus:outline-none focus:ring-2 focus:ring-primary"
              required
            />
          </div>

          {/* Apartment / Villa Number */}
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Villa, Suite, or Apartment (Optional)</label>
            <input
              type="text"
              name="apartment_number"
              value={address.apartment_number}
              onChange={handleInputChange}
              placeholder="e.g., Villa 12, Apt 3B"
              className="w-full p-4 bg-white rounded-lg border border-primary focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>

          {/* City */}
          <div>
            <label className="block text-gray-700 font-semibold mb-2">City <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="city"
              value={address.city}
              onChange={handleInputChange}
              placeholder="e.g., Dubai"
              className="w-full p-4 bg-white rounded-lg border border-primary focus:outline-none focus:ring-2 focus:ring-primary"
              required
            />
          </div>
        </div>

        {/* Additional Notes */}
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Special Instructions (Optional)</label>
          <textarea
            name="additional_notes"
            value={address.additional_notes}
            onChange={handleInputChange}
            placeholder="Add any special instructions (e.g., 'Call on arrival')"
            className="w-full p-4 bg-white rounded-lg border border-primary focus:outline-none focus:ring-2 focus:ring-primary"
            rows="3"
          />
        </div>

        {/* Payment Section */}
        <label className="block text-gray-700 font-semibold text-lg mt-8 mb-4">
          <FontAwesomeIcon icon={faCreditCard} className="mr-2 text-primary" />
          Secure Payment Details
        </label>
        <CardElement className="p-4 bg-white rounded-lg border border-primary" />

        {/* Error Message */}
        {error && <p className="text-red-500 mt-2">{error}</p>}

        {/* Action Buttons */}
        <div className="flex justify-between items-center mt-6">
          <button
            type="button"
            onClick={onBack}
            className="px-6 py-3 bg-light text-dark font-medium rounded-lg shadow-sm hover:bg-ash transition"
            disabled={loading}
          >
            Back
          </button>

          <button
            type="submit"
            className="px-6 py-3 bg-primary text-white font-bold rounded-lg shadow-sm hover:bg-dark transition"
            disabled={loading}
          >
            {loading ? "Processing..." : "Confirm Payment"}
          </button>
        </div>
      </form>
    </div>
  );
};




export const Step3 = ({ membership }) => {
  return (
    <div className="w-full max-w-lg bg-white rounded-3xl shadow-lg p-8 text-center">
      <h2 className="text-3xl font-bold text-primary mb-4">Thank You! </h2>
      <p className="text-body mb-6">
        You are now a proud member of the <strong>{membership.name}</strong>{" "}
        plan! Enjoy all the exclusive benefits and perks we have to offer.
      </p>
      <button
        className="px-6 py-2 bg-primary text-white rounded-lg shadow hover:bg-accent transition-transform transform hover:scale-105"
        onClick={() => alert("Redirect to dashboard")}
      >
        Go to Dashboard
      </button>
    </div>
  );
};



