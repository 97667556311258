import React, { useState, useEffect } from "react";

const ColorSelector = ({ formData, setFormData, colors }) => {
  const [selectedShade, setSelectedShade] = useState(""); // Selected shade filter
  const [searchTerm, setSearchTerm] = useState(""); // Search term for filtering
  const [filteredColors, setFilteredColors] = useState([]); // Filtered color list
  const [visibleColors, setVisibleColors] = useState([]); // Colors displayed in the grid
  const [selectedColor, setSelectedColor] = useState(formData.color || null); // Selected color
  const [itemsPerPage] = useState(12); // Number of colors to show initially or per load
  const [currentPage, setCurrentPage] = useState(1); // Tracks pagination

  // Extract unique shades for filters
  const shades = [...new Set(Object.values(colors).map((color) => color.shade))];

  // Filter colors based on search term and selected shade
  useEffect(() => {
    const filtered = Object.values(colors).filter((color) => {
      const matchesShade = selectedShade ? color.shade === selectedShade : true;
      const matchesSearch = searchTerm
        ? color.name.toLowerCase().includes(searchTerm.toLowerCase())
        : true;
      return matchesShade && matchesSearch;
    });

    setFilteredColors(filtered);
    setCurrentPage(1);
    setVisibleColors(filtered.slice(0, itemsPerPage));
  }, [selectedShade, searchTerm, colors]);

  // Sync with formData changes if needed
  useEffect(() => {
    setSelectedColor(formData.color || null);
  }, [formData.color]);

  // Load More Functionality
  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    const newColors = filteredColors.slice(0, nextPage * itemsPerPage);
    setVisibleColors(newColors);
    setCurrentPage(nextPage);
  };

  // Handle Shade Filter Selection
  const handleShadeFilter = (shade) => {
    setSelectedShade(shade);
  };

  // Handle Color Selection
  const handleColorSelect = (colorId) => {
    const newColor = selectedColor === colorId ? null : colorId; // Toggle color selection
    setSelectedColor(newColor);
    setFormData({ ...formData, color: newColor });
  };

  return (
    <div className="flex flex-col lg:flex-row gap-8 p-8 bg-light rounded-xl shadow-lg">
      {/* Left: Shade Filters */}
      <div className="w-full lg:w-1/5">
        <h3 className="text-2xl font-bold text-dark mb-6 uppercase tracking-wide">Shades</h3>
        <ul className="space-y-3">
          <li>
            <button
              type="button"
              className={`w-full text-left px-4 py-3 rounded-lg font-medium text-dark transition-all ${
                selectedShade === ""
                  ? "bg-primary text-white shadow-lg"
                  : "hover:bg-ash hover:shadow-md"
              }`}
              onClick={() => handleShadeFilter("")}
            >
              All
            </button>
          </li>
          {shades.map((shade, index) => (
            <li key={index}>
              <button
                type="button"
                className={`w-full text-left px-4 py-3 rounded-lg font-medium text-dark transition-all ${
                  selectedShade === shade
                    ? "bg-primary text-white shadow-lg"
                    : "hover:bg-ash hover:shadow-md"
                }`}
                onClick={() => handleShadeFilter(shade)}
              >
                {shade}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Right: Color Grid */}
      <div className="w-full lg:w-4/5">
        <h2 className="text-primary text-4xl font-extrabold mb-8 text-center uppercase tracking-wide">
          Pick Your Perfect Shade
        </h2>

        {/* Search Box */}
        <div className="mb-8 flex justify-center">
          <input
            type="text"
            placeholder="Search by color name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full max-w-md px-5 py-3 rounded-full border border-ash bg-white text-gray-700 focus:ring-2 focus:ring-primary focus:outline-none shadow-md"
          />
        </div>

        {/* Color Grid */}
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8">
          {visibleColors.map((color) => {
            const isSelected = selectedColor === color.id;

            return (
              <div
                key={color.id}
                onClick={() => handleColorSelect(color.id)}
                className={`flex flex-col items-center p-5 rounded-2xl cursor-pointer transition-all transform hover:scale-105 hover:shadow-xl ${
                  isSelected
                    ? "bg-primary text-white shadow-lg"
                    : "bg-white text-gray-700"
                }`}
              >
                <img
                  src={color.image}
                  alt={color.name}
                  className={`w-24 h-24 rounded-full object-cover mb-4 border-4 ${
                    isSelected ? "border-white" : "border-ash"
                  }`}
                />
                <p
                  className={`font-semibold text-lg ${
                    isSelected ? "text-white" : "text-tblue"
                  }`}
                >
                  {color.name}
                </p>
              </div>
            );
          })}
        </div>

        {/* Load More Button */}
        {visibleColors.length < filteredColors.length && (
          <div className="text-center mt-10">
            <button
              type="button"
              onClick={handleLoadMore}
              className="px-6 py-3 bg-primary text-white font-semibold rounded-full shadow-lg hover:bg-accent hover:scale-105 transition-all"
            >
              Load More
            </button>
          </div>
        )}

        {/* Empty State */}
        {visibleColors.length === 0 && (
          <div className="text-center text-gray-500 mt-10">
            <p>No colors available for the selected shade or search term.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorSelector;
