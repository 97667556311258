import { StatsCard } from "../components/StatsCard";
import { UserCard } from "../components/UserCard";
import React, { useState, useEffect, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Menu, Dialog, Transition, DialogBackdrop } from "@headlessui/react";
import api from "../api/api"; // Assuming your API utility is in this path
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { StaffCard } from "../components/StaffCard";
import LoadingLogo from "../components/Loading";

const Staff = () => {

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editData, setEditData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [services, setServices] = useState({});
    const [filter, setFilter] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [meta, setMeta] = useState({
        total: 0,
        per_page: 10,
        current_page: 1,
        last_page: 1,
        from: 1,
        to: 1
    });
    const [statData, setStatData] = useState({
        active: 0,
        viewed: 0,
    });
    const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);

    // Fetch tools on mount and when filters change
    useEffect(() => {
        fetchData();
    }, [filter, currentPage]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await api.get("/users", {
                params: {
                    type: "staff",
                    search: searchTerm,
                    filter,
                    page: currentPage,
                },
            });
            setUsers(response.data);
            setMeta(response.meta);
            setServices(response.services);
            setStatData((prev) => ({
                ...prev,
                active: response.active,
                viewed: response.viewed,
            }));
            setLoading(false);
        } catch (error) {
            toast.error("Failed to fetch");
            setLoading(false);
        }
    };


    const handleBooking = (user) => {
        setEditData(user);
        setIsBookingModalOpen(true);
    };

    const handleBookingCancel = () => {
        setIsBookingModalOpen(false);
    };


    const handleEdit = (user) => {
        setEditData(user);
        setIsModalOpen(true); // Show modal for editing
    };

    const handleAdd = () => {
        setEditData(null); // Reset any edit data
        setIsModalOpen(true); // Show modal for adding
    };

    const handleSave = () => {
        setIsModalOpen(false); // Hide modal after saving
        fetchData();
    };

    const handleCancel = () => {
        setIsModalOpen(false); // Hide modal without saving
    };

    // Pagination handlers
    const handleNextPage = () => {
        if (meta.current_page < meta.last_page) {
            setCurrentPage(meta.current_page + 1);
        }
    };

    const handlePrevPage = () => {
        if (meta.current_page > 1) {
            setCurrentPage(meta.current_page - 1);
        }
    };

    // Render individual page buttons
    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= meta.last_page; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => setCurrentPage(i)}
                    className={`px-3 py-1 rounded-lg border border-primary ${i === meta.current_page ? 'bg-primary text-white' : 'bg-white text-gray-700'
                        } hover:bg-primary hover:text-white mx-1`}
                >
                    {i}
                </button>
            );
        }
        return pages;
    };


    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this user?")) {
            try {
                await api.delete(`/users/${id}`);
                toast.success("Staff deleted successfully.");
                fetchData(); // Refresh data
            } catch (error) {
                toast.error("Failed to delete user.");
            }
        }
    };


    if (loading) {
        return <LoadingLogo />;
    }

    return (
        <div className="p-6 ">
            <div className="grid grid-cols-12 gap-4 w-full mb-6">
                {/* Search Bar (Column 1) */}
                <div className="col-span-10 relative ">
                    <input
                        type="text"
                        className="w-full py-2 pl-4 pr-4 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:outline-none  focus:ring-primary"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} // Keep updating state as user types
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                fetchData(); // Only set searchTerm on Enter key press
                            }
                        }}
                    />
                    <svg
                        className="absolute right-3 top-3 w-5 h-5 "
                        width="24"
                        height="25"
                        viewBox="0 0 20 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M21.7444 24.25L16.8389 19.6C16.0333 20.2444 15.1069 20.7546 14.0597 21.1306C13.0125 21.5065 11.8981 21.6944 10.7167 21.6944C7.78982 21.6944 5.31273 20.6808 3.28542 18.6535C1.2581 16.6262 0.244446 14.1491 0.244446 11.2222C0.244446 8.29537 1.2581 5.81829 3.28542 3.79097C5.31273 1.76366 7.78982 0.75 10.7167 0.75C13.6435 0.75 16.1206 1.76366 18.1479 3.79097C20.1752 5.81829 21.1889 8.29537 21.1889 11.2222C21.1889 12.4037 21.0009 13.5181 20.625 14.5653C20.2491 15.6125 19.7389 16.5389 19.0944 17.3444L24 21.9944L21.7444 24.25ZM10.7167 18.4722C12.7306 18.4722 14.4424 17.7674 15.8521 16.3576C17.2618 14.9479 17.9667 13.2361 17.9667 11.2222C17.9667 9.20833 17.2618 7.49653 15.8521 6.08681C14.4424 4.67708 12.7306 3.97222 10.7167 3.97222C8.70278 3.97222 6.99097 4.67708 5.58125 6.08681C4.17153 7.49653 3.46667 9.20833 3.46667 11.2222C3.46667 13.2361 4.17153 14.9479 5.58125 16.3576C6.99097 17.7674 8.70278 18.4722 10.7167 18.4722Z"
                            fill="#CACACA"
                        />
                    </svg>
                </div>

                {/* Add New Staff Button (Column 2) */}
                <div className="col-span-2 flex justify-end">
                    <button onClick={handleAdd} className="px-4 py-2 text-white bg-primary rounded-lg flex items-center hover:bg-accent focus:outline-none focus:ring-2 focus:ring-accent">
                        + Add New Staff
                    </button>
                </div>
            </div>

            {/* Search Bar */}

            {/* Stats Cards */}
            <div className="grid grid-cols-3 gap-6 mb-12">
                <StatsCard title="Total Staff" value={meta.total} />
                <StatsCard title="Active Runtime" value={statData.active} />
            </div>

            {/* Staff List */}
            <div className="space-y-4">
                {users.map((user, index) => (
                    <StaffCard
                        key={user.id}
                        handleBooking={() => handleBooking(user)} // Pass the user to the handleDelete function
                        user={user}
                        handleEdit={() => handleEdit(user)} // Pass the user to the handleEdit function
                        handleDelete={() => handleDelete(user.id)} // Pass the user to the handleDelete function
                    />
                ))}
            </div>

            {/* Pagination */}
            <div className="mt-4 flex justify-between items-center">
                {/* Total Count and Pagination Info */}
                <span className="text-gray-500">
                    Showing {meta.from} to {meta.to} of {meta.total} staffs
                </span>

                {/* Page Number Buttons */}
                <div className="flex items-center space-x-2">
                    <button
                        onClick={handlePrevPage}
                        disabled={meta.current_page === 1}
                        className={`px-4 py-2 text-sm text-gray-500 bg-white border border-gray-200 rounded-lg ${meta.current_page === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-400'
                            }`}
                    >
                        Previous
                    </button>

                    {/* Render dynamic page numbers */}
                    {renderPageNumbers()}

                    <button
                        onClick={handleNextPage}
                        disabled={meta.current_page === meta.last_page}
                        className={`px-4 py-2 text-sm text-gray-500 bg-white border border-gray-200 rounded-lg ${meta.current_page === meta.last_page ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-400'
                            }`}
                    >
                        Next
                    </button>
                </div>
            </div>

            {/* CRUD Modal for Adding/Editing */}
            {isModalOpen && (
                <CRUModal
                    item={editData}
                    onSave={handleSave}
                    onCancel={handleCancel}
                    services={services}
                />
            )}

            {isBookingModalOpen && (
                <BookingModal
                    item={editData}
                    onCancel={handleBookingCancel}
                />
            )}

        </div>
    );
};

export default Staff;


const CRUModal = ({ item, onSave, onCancel, services = {} }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        dob: '',
        phone: '+971', // Default to Dubai country code
        selectedServices: [],
        availability: [],
    });

    const [availability, setAvailability] = useState(
        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => ({
            day,
            isAvailable: false,
            startTime: '',
            endTime: '',
        }))
    );


    // Populate form data when editing
    useEffect(() => {
        if (item) {
            setFormData({
                name: item.name || '',
                email: item.email || '',
                emirates_id: item.emirates_id || '',
                dob: item.dob || '',
                phone: item.phone || '+971',
                password: '', // Leave blank for security
                picture: item.profile_photo || null,
                selectedServices: item.services.map((service) => service.id), // Extract service IDs
            });

            setAvailability((prevAvailability) =>
                prevAvailability.map((day) => {
                    const matchingDay = item.availability.find((avail) => avail.day === day.day);
                    console.log(matchingDay)
                    return matchingDay
                        ? {
                            ...day,
                            isAvailable: true,
                            startTime: matchingDay.start_time,
                            endTime: matchingDay.end_time,
                        }
                        : { ...day, isAvailable: false, startTime: '', endTime: '' };
                })
            );
        }
    }, [item]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, phone: value });
    };

    const handleServiceChange = (serviceId) => {
        setFormData((prev) => {
            const isSelected = prev.selectedServices.includes(serviceId);
            return {
                ...prev,
                selectedServices: isSelected
                    ? prev.selectedServices.filter((id) => id !== serviceId)
                    : [...prev.selectedServices, serviceId],
            };
        });
    };

    const handleAvailabilityChange = (index, field, value) => {
        setAvailability((prev) =>
            prev.map((item, i) =>
                i === index
                    ? {
                        ...item,
                        [field]: field === 'isAvailable' ? !item.isAvailable : value,
                    }
                    : item
            )
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                ...formData,
                availability: availability.map((day, index) => ({
                    day: index, // 0 for Sunday, 1 for Monday, etc.
                    isAvailable: day.isAvailable,
                    startTime: day.startTime,
                    endTime: day.endTime,
                })),
            };

            if (item) {
                await api.post(`/users/${item.id}`, {
                    ...payload,
                    _method: 'PUT',
                    type: "staff",
                });
                toast.success("Staff updated successfully");
            } else {
                await api.post(`/users`, {
                    ...payload,
                    type: "staff",
                });
                toast.success("Staff created successfully");
            }
            onSave();
        } catch (error) {
            toast.error("An error occurred. Please try again.");
        }
    };

    const handlePictureUpload = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= 2 * 1024 * 1024) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData({ ...formData, picture: reader.result });
            };
            reader.readAsDataURL(file);
        } else {
            alert("File size must be 2MB or less.");
        }
    };



    // Split services into Extra and Non-Extra categories
    const extraServices = Object.values(services).filter((service) => service.is_extra);
    const primaryServices = Object.values(services).filter((service) => !service.is_extra);

    return (
        <Transition appear show={true} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onCancel}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-6xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    {item ? 'Edit Staff' : 'Add New Staff'}
                                </Dialog.Title>
                                <div className="mt-4">
                                    <form onSubmit={handleSubmit}>

                                        {/* Personal Information */}
                                        <div className="mb-8 p-6 bg-white border rounded-lg shadow-md">
                                            <h3 className="text-2xl font-semibold text-center text-primary mb-6">Personal Information</h3>
                                            <hr className="mb-6 border-ash" />

                                            {/* Two-Column Layout */}
                                            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                                                {/* Picture Upload */}
                                                <div className="flex flex-col items-center">
                                                    <div className="w-32 h-32 border-2 border-dashed border-ash rounded-full overflow-hidden shadow-md flex items-center justify-center bg-bodyMain">
                                                        {formData.picture ? (
                                                            <img
                                                                src={formData.picture}
                                                                alt="Uploaded Preview"
                                                                className="w-full h-full object-cover"
                                                            />
                                                        ) : (
                                                            <span className="text-gray-500">Upload Image</span>
                                                        )}
                                                    </div>
                                                    <label
                                                        htmlFor="pictureUpload"
                                                        className="mt-4 bg-primary text-white px-4 py-2 rounded-md cursor-pointer hover:bg-secondary transition-all"
                                                    >
                                                        Upload Picture
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="pictureUpload"
                                                        name="picture"
                                                        accept="image/*"
                                                        className="hidden"
                                                        onChange={(e) => handlePictureUpload(e)}
                                                    />
                                                    <p className="text-sm text-gray-500 mt-2">Max size: 2MB</p>
                                                </div>

                                                {/* Name, Email, and Password */}
                                                <div className="col-span-2 ">
                                                    <div className="grid grid-cols-2 gap-6  items-center justify-center">
                                                        {/* Name */}
                                                        <div>
                                                            <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                value={formData.name}
                                                                onChange={handleChange}
                                                                required
                                                                className="block w-full p-3 border border-ash rounded-lg focus:ring-primary focus:border-primary"
                                                            />
                                                        </div>

                                                        {/* Email */}
                                                        <div>
                                                            <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                                                            <input
                                                                type="email"
                                                                name="email"
                                                                value={formData.email}
                                                                onChange={handleChange}
                                                                required
                                                                className="block w-full p-3 border border-ash rounded-lg focus:ring-primary focus:border-primary"
                                                            />
                                                        </div>



                                                        {/* Password */}
                                                        <div className="col-span-2">
                                                            <label className="block text-sm font-medium text-gray-700 mb-1">Password</label>
                                                            <input
                                                                type="password"
                                                                name="password"
                                                                value={formData.password}
                                                                onChange={handleChange}
                                                                required={!item}
                                                                className="block w-full p-3 border border-ash rounded-lg focus:ring-primary focus:border-primary"
                                                                placeholder={item ? 'Leave blank to keep current password' : ''}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Full-Width Fields */}
                                            <div className="mt-10">
                                                {/* Phone */}
                                                <div className="mb-6">
                                                    <label className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
                                                    <PhoneInput
                                                        country={'ae'}
                                                        value={formData.phone}
                                                        onChange={handlePhoneChange}
                                                        inputClass="!w-full p-5 !pl-12 border border-ash rounded-lg focus:ring-primary focus:border-primary"
                                                        searchPlaceholder="Search countries"
                                                        enableSearch={true}
                                                        containerClass="!w-full !border !rounded-lg flex"
                                                        dropdownClass="!bg-white !shadow-lg !rounded-lg !p-2"
                                                        autoFormat={true}
                                                    />
                                                </div>


                                                {/* DOB */}
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700 mb-1">Date of Birth</label>
                                                    <input
                                                        type="date"
                                                        name="dob"
                                                        value={formData.dob}
                                                        onChange={handleChange}
                                                        className="block w-full p-3 border border-ash rounded-lg focus:ring-primary focus:border-primary"
                                                    />
                                                </div>
                                            </div>
                                        </div>



                                        {/* Services */}
                                        <div className="mb-8 p-6 bg-white border rounded-lg ">
                                            <h3 className="text-3xl font-bold text-center text-primary mb-6">Manage Services</h3>
                                            <hr className="mb-6 border-ash" />

                                            {/* Primary Services */}
                                            <h3 className="text-xl font-semibold text-center text-primary mb-8">Primary Services</h3>
                                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                                                {primaryServices.map((service) => (
                                                    <div
                                                        key={service.id}
                                                        className={`relative group p-6 rounded-lg shadow-xl border-2 transition-transform transform hover:scale-105 hover:shadow-2xl ${formData.selectedServices.includes(service.id)
                                                            ? 'bg-gradient-to-br from-gradientStart to-gradientEnd border-accent'
                                                            : 'bg-bodyMain border-ash'
                                                            }`}
                                                    >
                                                        {/* Icon (Optional, Placeholder for now) */}
                                                        <div
                                                            className={`absolute -top-6 left-1/2 transform -translate-x-1/2 bg-white rounded-full w-12 h-12 shadow-lg flex items-center justify-center ${formData.selectedServices.includes(service.id) ? 'bg-accent text-white' : 'bg-light text-primary'
                                                                }`}
                                                        >
                                                            <img src="/assets/logo.png" />

                                                        </div>

                                                        {/* Service Details */}
                                                        <div className="text-center mt-8">
                                                            <h4
                                                                className={`text-lg font-semibold ${formData.selectedServices.includes(service.id) ? 'text-white' : 'text-dark'
                                                                    }`}
                                                            >
                                                                {service.formatted_name}
                                                            </h4>
                                                            <p
                                                                className={`text-sm ${formData.selectedServices.includes(service.id) ? 'text-light' : 'text-body'
                                                                    }`}
                                                            >
                                                                {service.formatted_duration} - {service.price} AED
                                                            </p>
                                                        </div>

                                                        {/* Checkbox */}
                                                        <div className="flex justify-center mt-6">
                                                            <label className="inline-flex items-center space-x-3 cursor-pointer">
                                                                <input
                                                                    type="checkbox"
                                                                    id={`service-${service.id}`}
                                                                    checked={formData.selectedServices.includes(service.id)}
                                                                    onChange={() => handleServiceChange(service.id)}
                                                                    className="sr-only"
                                                                />
                                                                <div
                                                                    className={`w-6 h-6 flex items-center justify-center rounded-full transition-all ${formData.selectedServices.includes(service.id)
                                                                        ? 'bg-accent text-white'
                                                                        : 'bg-light border border-ash text-dark'
                                                                        }`}
                                                                >
                                                                    {formData.selectedServices.includes(service.id) && (
                                                                        <i className="fas fa-check"></i> /* Show check icon if selected */
                                                                    )}
                                                                </div>
                                                                <span
                                                                    className={`text-sm font-medium ${formData.selectedServices.includes(service.id) ? 'text-white' : 'text-dark'
                                                                        }`}
                                                                >
                                                                    {formData.selectedServices.includes(service.id) ? 'Selected' : 'Select'}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            {/* Extra Services */}
                                            <h3 className="text-xl font-semibold text-center text-primary mt-12 mb-8">Extra Services</h3>
                                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                                                {extraServices.map((service) => (
                                                    <div
                                                        key={service.id}
                                                        className={`relative group p-6 rounded-lg shadow-xl border-2 transition-transform transform hover:scale-105 hover:shadow-2xl ${formData.selectedServices.includes(service.id)
                                                            ? 'bg-gradient-to-br from-gradientStart to-gradientEnd border-accent'
                                                            : 'bg-bodyMain border-ash'
                                                            }`}

                                                    >
                                                        {/* Icon */}
                                                        <div
                                                            className={`absolute -top-6 left-1/2 transform -translate-x-1/2 bg-white rounded-full w-12 h-12 shadow-lg flex items-center justify-center ${formData.selectedServices.includes(service.id) ? 'bg-accent text-white' : 'bg-light text-primary'
                                                                }`}
                                                        >
                                                            <img src="/assets/logo.png" />
                                                        </div>

                                                        {/* Service Details */}
                                                        <div className="text-center mt-8">
                                                            <h4
                                                                className={`text-lg font-semibold ${formData.selectedServices.includes(service.id) ? 'text-white' : 'text-dark'
                                                                    }`}
                                                            >
                                                                {service.formatted_name}
                                                            </h4>
                                                            <p
                                                                className={`text-sm ${formData.selectedServices.includes(service.id) ? 'text-light' : 'text-body'
                                                                    }`}
                                                            >
                                                                {service.formatted_duration} - {service.price} AED
                                                            </p>
                                                        </div>

                                                        {/* Checkbox */}
                                                        <div className="flex justify-center mt-6">
                                                            <label className="inline-flex items-center space-x-3 cursor-pointer">
                                                                <input
                                                                    type="checkbox"
                                                                    id={`service-${service.id}`}
                                                                    checked={formData.selectedServices.includes(service.id)}
                                                                    onChange={() => handleServiceChange(service.id)}
                                                                    className="sr-only"
                                                                />
                                                                <div
                                                                    className={`w-6 h-6 flex items-center justify-center rounded-full transition-all ${formData.selectedServices.includes(service.id)
                                                                        ? 'bg-accent text-white'
                                                                        : 'bg-light border border-ash text-dark'
                                                                        }`}
                                                                >
                                                                    {formData.selectedServices.includes(service.id) && (
                                                                        <i className="fas fa-check"></i>
                                                                    )}
                                                                </div>
                                                                <span
                                                                    className={`text-sm font-medium ${formData.selectedServices.includes(service.id) ? 'text-white' : 'text-dark'
                                                                        }`}
                                                                >
                                                                    {formData.selectedServices.includes(service.id) ? 'Selected' : 'Select'}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>


                                        {/* Availability */}
                                        <div className="mb-6 p-6 bg-white border rounded-lg shadow-lg">
                                            <h3 className="text-2xl font-semibold text-center text-primary mb-4">Staff Availability</h3>
                                            <hr className="mb-4 border-ash" />

                                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                                {availability.map((item, index) => (
                                                    <div
                                                        key={item.day}
                                                        className={`p-4 rounded-lg shadow-lg border ${item.isAvailable
                                                            ? 'bg-ash border-grey'
                                                            : 'bg-red-100 border-red'
                                                            } transition-transform transform hover:scale-105 hover:shadow-xl`}
                                                    >
                                                        {/* Day */}
                                                        <div className="flex items-center justify-between mb-2">
                                                            <span className="text-lg font-semibold text-dark">{item.day}</span>
                                                            <div className="flex items-center">
                                                                <label
                                                                    className={`relative inline-flex items-center cursor-pointer`}
                                                                    title={item.isAvailable ? 'Available' : 'Unavailable'}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={item.isAvailable}
                                                                        onChange={() => handleAvailabilityChange(index, 'isAvailable')}
                                                                        className="sr-only"
                                                                    />
                                                                    <div
                                                                        className={`w-10 h-6 ${item.isAvailable ? 'bg-accent' : 'bg-red-300'
                                                                            } rounded-full transition-all`}
                                                                    ></div>
                                                                    <span
                                                                        className={`absolute top-0.5 left-1 ${item.isAvailable
                                                                            ? 'translate-x-4 bg-light'
                                                                            : '-translate-x-0.5 bg-red-600'
                                                                            } w-5 h-5 rounded-full shadow transition-transform`}
                                                                    ></span>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        {/* Time Inputs */}
                                                        <div className="flex items-center justify-between mt-2">
                                                            <input
                                                                type="time"
                                                                value={item.startTime}
                                                                onChange={(e) => handleAvailabilityChange(index, 'startTime', e.target.value)}
                                                                className="w-24 p-2 text-sm border border-ash rounded-md focus:ring-accent focus:border-accent bg-bodyMain text-dark"
                                                                disabled={!item.isAvailable}
                                                                placeholder="Start"
                                                            />
                                                            <span className="mx-2 text-dark">to</span>
                                                            <input
                                                                type="time"
                                                                value={item.endTime}
                                                                onChange={(e) => handleAvailabilityChange(index, 'endTime', e.target.value)}
                                                                className="w-24 p-2 text-sm border border-ash rounded-md focus:ring-accent focus:border-accent bg-bodyMain text-dark"
                                                                disabled={!item.isAvailable}
                                                                placeholder="End"
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>



                                        {/* Buttons */}
                                        <div className="flex justify-end mt-6">
                                            <button
                                                type="button"
                                                onClick={onCancel}
                                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg mr-2"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary"
                                            >
                                                {item ? 'Update' : 'Save'}
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>

            </Dialog>
        </Transition>
    );
};


export const BookingModal = ({ item, onCancel }) => {
    const [loading, setLoading] = useState(false);
  
    if (!item) return null;
  
    const user = item;
  
    return (
      <Transition appear show as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onCancel}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  
                  {/* User Info */}
                  <div className="p-6 bg-gradient-to-r from-primary to-gradientEnd text-white rounded-t-2xl">
                    <div className="flex items-center space-x-6">
                      <img 
                        src={user.profile_photo || 'https://via.placeholder.com/150'} 
                        alt="User Avatar" 
                        className="w-20 h-20 rounded-full border-4 border-white object-cover"
                      />
                      <div>
                        <h2 className="text-2xl font-bold">{user.name}</h2>
                        <p className="text-sm">Username: <strong>{user.username}</strong></p>
                        <p className="text-sm">Email: <strong>{user.email}</strong></p>
                        <p className="text-sm">Phone: <strong>{user.phone || 'N/A'}</strong></p>
                        <p className="text-sm">Emirates ID: <strong>{user.emirates_id}</strong></p>
                        <p className="text-sm">Joined: <strong>{user.created_at}</strong></p>
                      </div>
                    </div>
                  </div>
  
                  {/* Booking List */}
                  <div className="p-6">
                    <h3 className="text-xl font-bold text-dark mb-4">Bookings</h3>
  
                    {user.bookings.length > 0 ? (
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        {user.bookings.map((booking) => (
                          <div 
                            key={booking.id} 
                            className="bg-light p-6 rounded-lg shadow-md hover:shadow-lg transition-transform hover:scale-105"
                          >
                            <h4 className="text-lg font-bold text-primary mb-2">
                              Booking ID: #{booking.id}
                            </h4>
  
                            <p className="text-sm">
                              <strong>Status:</strong> 
                              <span className={`ml-2 px-2 py-1 rounded-full ${booking.status === 'booked' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                                {booking.status}
                              </span>
                            </p>
  
                            <p className="mt-2 text-sm">
                              <strong>Date:</strong> {new Date(booking.day).toLocaleDateString('en-US')}
                            </p>
  
                            <p className="mt-2 text-sm">
                              <strong>Time:</strong> {booking.start_time} - {booking.end_time}
                            </p>
  
                            <p className="mt-2 text-sm">
                              <strong>Total Price:</strong> AED {booking.total_price || 'N/A'}
                            </p>
  
                            {booking.address && (
                              <div className="mt-4 bg-white p-4 rounded-lg shadow-inner">
                                <h5 className="text-sm font-bold text-dark">Address</h5>
                                <p className="text-sm">{booking.address.street_address}, Apt {booking.address.apartment_number}</p>
                                <p className="text-sm">{booking.address.city}, {booking.address.postal_code}</p>
                                <p className="text-sm text-gray-500 mt-1">Notes: {booking.address.additional_notes}</p>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-center text-gray-500">No bookings available.</p>
                    )}
                  </div>
  
                  {/* Footer */}
                  <div className="flex justify-end p-6">
                    <button
                      onClick={onCancel}
                      className="bg-gray-500 text-white px-6 py-2 rounded-full hover:bg-gray-700 transition-all"
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };



